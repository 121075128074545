import { blindsServices } from '../services/blinds.service';
import { blindsConstants } from '../constants/blinds.constants';

function getBlinds() {
  return (dispatch) => {
    const promise = blindsServices.getBlinds();

    promise.then(
      (blinds) => {
        dispatch(success(blinds));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(blinds) {
    return { type: blindsConstants.GET_BLINDS_SUCCESS, blinds };
  }

  function failure(error) {
    return { type: blindsConstants.GET_BLINDS_FAILURE, error };
  }
}

function addBlinds(blindsname, blindsstructure) {
  return (dispatch) => {
    return blindsServices
      .addBlinds(blindsname, blindsstructure) // Return this promise
      .then(
        (response) => {
          // Clean up the response and extract the JSON part
          const jsonString = response.substring(response.indexOf('{"success"')); // Find where JSON starts
          let parsedResult;

          try {
            // Parse the extracted JSON string
            parsedResult = JSON.parse(jsonString);
          } catch (error) {
            console.error('Failed to parse JSON:', error);
            throw error; // Handle parsing errors
          }

          dispatch(success(parsedResult.result));
          return parsedResult.result; // Return the entire result object
        },
        (error) => {
          dispatch(failure(error));
          throw error; // Throw the error so it can be caught in the component
        }
      );
  };

  function success(blinds) {
    return {
      type: blindsConstants.ADD_BLINDS_SUCCESS,
      blinds: { ...blinds },
    };
  }

  function failure(error) {
    return { type: blindsConstants.ADD_BLINDS_FAILURE, error };
  }
}

function deleteBlinds(blindsId) {
  return (dispatch) => {
    const promise = blindsServices.deleteBlinds(blindsId);

    promise.then(
      () => {
        dispatch(success(blindsId));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(blindsId) {
    return { type: blindsConstants.DELETE_BLINDS_SUCCESS, blindsId };
  }

  function failure(error) {
    return { type: blindsConstants.DELETE_BLINDS_FAILURE, error };
  }
}

function prevBlindStruct(blinds) {
  return {
    type: blindsConstants.PREV_BLIND_STRUCT_SUCCESS,
    blinds: blinds,
  };
}

function nextBlindStruct(blinds) {
  return {
    type: blindsConstants.NEXT_BLIND_STRUCT_SUCCESS,
    blinds: blinds,
  };
}

function updateBlindStruct(idx, struct) {
  return {
    type: blindsConstants.UPDATE_BLINDS_SUCCESS,
    idx: idx,
    struct: struct,
  };
}

function selectBlindStruct(blindId) {
  return {
    type: blindsConstants.SELECT_BLIND_STRUCT,
    payload: blindId,
  };
}

export const blindsActions = {
  getBlinds,
  addBlinds,
  deleteBlinds,
  prevBlindStruct,
  nextBlindStruct,
  updateBlindStruct,
  selectBlindStruct,
};
