import { userActions as userService } from "../services/user.service";
import { userConstants } from "../constants/user.constants";
import { removeJWT } from "../helpers/auth";

function login(username, password) {
  return (dispatch) => {
    const promise = userService.login(username, password);

    promise.then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(user) {
    return {
      type: userConstants.LOGIN_SUCCESS,
      jwt: user.jwt,
      leagueid: user.leagueid,
    };
  }
  function failure(error) {
    return {
      type: userConstants.LOGIN_FAILURE,
      error: error.response.data.error,
    };
  }
}

function logout() {
  return (dispatch) => {
    const promise = userService.logout();

    promise.finally(() => {
      removeJWT();
      dispatch(success());
    });
  };

  function success() {
    return {
      type: userConstants.LOGOUT,
    };
  }
}

export const userActions = {
  login,
  logout,
};
