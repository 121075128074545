import { combineReducers } from "redux";
import { gameReducer } from "./game";
import { userReducer } from "./user";
import { ledgerReducer } from "./ledger";
import { userConstants } from "../constants/user.constants";

const appReducer = combineReducers({
  user: userReducer,
  game: gameReducer,
  ledger: ledgerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
