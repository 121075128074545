import axios from "axios";
import store from "../store";
import { userActions } from "../actions/user.actions";

const AuthenticatedEndpoint = axios.create({
  baseURL: `https://www.travispokertimer.com/pokeradmin/timer-api.php`,
});

AuthenticatedEndpoint.interceptors.request.use(
  (config) => {
    config.headers["authorization"] = "Bearer " + store.getState().user.jwt;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

AuthenticatedEndpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(userActions.logout());
    }
    return error;
  }
);

AuthenticatedEndpoint.interceptors.response.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default AuthenticatedEndpoint;
