export const playersConstants = {
  GET_PLAYERS_SUCCESS: "GET_PLAYERS_SUCCESS",
  GET_PLAYERS_FAILURE: "GET_PLAYERS_FAILURE",

  SET_DEFAULT_BUYIN: "SET_DEFAULT_BUYIN",

  BUST_PLAYER_SUCCESS: "BUST_PLAYER_SUCCESS",
  UNBUST_PLAYER_SUCCESS: "UNBUST_PLAYER_SUCCESS",

  UPDATE_BUYIN_SUCCESS: "UPDATE_BUYIN_SUCCESS",

  REBUY_PLAYER_SUCCESS: "REBUY_PLAYER_SUCCESS",
  ADDON_PLAYER_SUCCESS: "ADDON_PLAYER_SUCCESS",
};
