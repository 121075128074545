import React from "react";

import { Button, Icon, Image, List } from "semantic-ui-react";
import { connect } from "react-redux";
import PlayerExtras from "./PlayerExtras";
import { addToLedger } from "../actions/ledger.actions";
import { playersActions } from "../actions/players.actions";

class TournamentPlayersWidgerPlayer extends React.Component {
  isPlayerBust() {
    return this.props.bustPlayers.includes(this.props.id);
  }

  lineStyle() {
    if (this.isPlayerBust() === true) {
      return { textDecorationLine: "line-through" };
    } else {
      return {};
    }
  }

  bustPlayerEvent() {
    if (this.isPlayerBust() === true) {
      this.props.unbustPlayer(this.props.id);
    } else {
      this.props.bustPlayer(this.props.id);
    }
  }

  bustPlayerPosition(playerId) {
    const position = this.props.bustPlayers.indexOf(playerId.toString());

    if (position >= 0) {
      return `[${this.props.tournamentPlayers.length - position}] `;
    } else {
      return "";
    }
  }

  render() {
    return (
      <List.Item>
        <List.Content>
          <List.Header
            style={this.lineStyle()}
            className="tournament-players-names"
          >
            <Image avatar src={this.props.photo} />
            {this.bustPlayerPosition(this.props.id)}
            {this.props.name}
          </List.Header>
        </List.Content>
        <div className="tournament-players-list">
          <PlayerExtras
            icon="redo"
            buyinPlayer={true}
            title="Player Re-buy"
            playerId={this.props.id}
            playerName={this.props.name}
            playerPhoto={this.props.photo}
            rebuy={true}
            display={
              this.props.inPlay &&
              this.props.bustPlayers.includes(this.props.id.toString())
            }
          />
          <PlayerExtras
            icon="level up"
            buyinPlayer={false}
            title="Player Addon"
            playerId={this.props.id}
            playerName={this.props.name}
            playerPhoto={this.props.photo}
            display={
              this.props.inPlay &&
              !this.props.bustPlayers.includes(this.props.id.toString())
            }
          />
          <Button
            icon
            floated="right"
            onClick={(e) => {
              this.bustPlayerEvent();
            }}
            style={{ display: this.props.inPlay ? "inline-block" : "none" }}
          >
            <Icon name="delete" />
          </Button>
        </div>
      </List.Item>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bustPlayers: state.game.tournament.bust,
    tournamentPlayers: state.game.tournament.players,
    inPlay: state.game.tournament.inPlay,
  };
};
export default connect(mapStateToProps, {
  bustPlayer: playersActions.bustPlayer,
  unbustPlayer: playersActions.unbustPlayer,
  addToLedger: addToLedger,
})(TournamentPlayersWidgerPlayer);
