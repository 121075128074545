export function generateStatsPayload(
  tournamentid,
  tournamentdate,
  tournamentname,
  tournamentvenueid,
  tournamentfinalstats
) {
  let payload = {
    tournamentid: tournamentid,
    tournamentdate: tournamentdate,
    tournamentname: tournamentname,
    tournamentvenueid: tournamentvenueid,
    tournamentresults: [],
  };
  tournamentfinalstats.forEach((player, idx) => {
    const position = idx + 1;

    const playerResult = {
      playerid: player.id,
      position: position,
      points: player.points,
      profit: player.profit,
    };
    payload.tournamentresults.push(playerResult);
  });
  return payload;
}
