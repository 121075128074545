import React from "react";

import { Dropdown } from "semantic-ui-react";

import { connect } from "react-redux";
import { venuesActions } from "../actions/venues.actions";

class Venues extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venueSearchValue: null,
    };
  }

  transformVenues(venues) {
    let transformedVenues = [];
    venues.forEach(function (venue) {
      transformedVenues.push({
        key: venue.id,
        value: venue.id,
        text: venue.name,
      });
    });

    return transformedVenues;
  }

  render(props) {
    const { venueSearchValue } = this.state;
    const venues = this.transformVenues(this.props.venues);

    return (
      <Dropdown
        clearable
        fluid
        search
        selection
        options={venues}
        value={this.props.value}
        onSearchChange={(a) =>
          this.setState({ venueSearchValue: a.target.value })
        }
        placeholder="Select Venue..."
        noResultsMessage={
          <Dropdown.Item
            text={`Create new venue '` + venueSearchValue + `'`}
            icon="add square"
            onClick={(e) => this.props.createVenue(venueSearchValue)}
          />
        }
        onChange={this.props.onChange}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    venues: state.game.venues,
  };
};

export default connect(mapStateToProps, {
  createVenue: venuesActions.createVenue,
})(Venues);
