import React from "react";
import { Statistic } from "semantic-ui-react";
import { connect } from "react-redux";
import { ledgerTotals } from "../helpers/ledger";

class PotStatsWidget extends React.Component {
  getPlayerAvgChips() {
    if (this.props.tournamentPlayers.length === 0) {
      return "n/a";
    }

    const totalChips = ledgerTotals(this.props.ledger).totalChips;

    const remainingPlayers =
      this.props.tournamentPlayers.length - this.props.bustPlayers.length;

    if (remainingPlayers > 0) {
      return Math.floor(
        totalChips /
          (this.props.tournamentPlayers.length - this.props.bustPlayers.length)
      );
    } else {
      return 0;
    }
  }

  getPlayersLabel() {
    return this.props.tournamentPlayers.length === 1 ? "Player" : "Players";
  }

  render(props) {
    return (
      <Statistic.Group horizontal>
        <Statistic className="chips-statistics">
          <Statistic.Label className="stat-label-timer">
            Chip Avg:{" "}
          </Statistic.Label>
          <Statistic.Value>{this.getPlayerAvgChips()}</Statistic.Value>
        </Statistic>
        <Statistic className="chips-statistics">
          <Statistic.Label className="stat-label-timer">
            Total Chips:{" "}
          </Statistic.Label>
          <Statistic.Value>
            {ledgerTotals(this.props.ledger).totalChips}
          </Statistic.Value>
        </Statistic>
        <Statistic className="chips-statistics">
          <Statistic.Label className="stat-label-timer">
            {this.getPlayersLabel()}:{" "}
          </Statistic.Label>
          <Statistic.Value>
            {this.props.tournamentPlayers.length -
              this.props.bustPlayers.length}
          </Statistic.Value>
        </Statistic>
      </Statistic.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pot: state.game.tournament.pot,
    ledger: state.ledger,
    tournamentPlayers: state.game.tournament.players,
    bustPlayers: state.game.tournament.bust,
  };
};

export default connect(mapStateToProps, {})(PotStatsWidget);
