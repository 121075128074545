import {
  Button,
  Container,
  Grid,
  Header,
  Menu,
  Modal,
  Responsive,
  Segment,
} from 'semantic-ui-react';
import Players from './Players';
import BlindsWidget from './BlindsWidget';
import TournamentSelector from './TournamentSelector';
import React from 'react';
import { connect } from 'react-redux';
import { blindsActions } from '../actions/blinds.actions';

import { bindActionCreators } from 'redux';
import { playersActions } from '../actions/players.actions';
import { venuesActions } from '../actions/venues.actions';
import { tournamentsActions } from '../actions/tournaments.actions';
import { userActions } from '../actions/user.actions';
import { Redirect } from 'react-router';
import { timerActions } from '../actions/timer.actions';
import ChipsIndicator from './ChipsIndicator';
import Prizes from './Prizes';
import { prizesActions } from '../actions/prizes.actions';
import TournamentStats from './TournamentStats';
import TournamentPlayersWidget from './TournamentPlayersWidget';
import PrizePoolWidget from './PrizePoolWidget';
import PotStatsWidget from './PotStatsWidget';
import ClockWidget from './ClockWidget';
import LedgerVisualisation from './LedgerVisualisation';
import Blinds from './Blinds';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      secondsRemaining: 0,
      paused: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      showLogoutConfirmModal: false,
    };

    this.intervalHandle = setInterval(() => this.props.tick(), 1000);
  }

  handleResize = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  componentWillUnmount() {
    window.addEventListener('resize', this.handleResize);
  }

  setSecondsRemaining(secondsRemaining) {
    this.props.setSecondsRemaining(secondsRemaining);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.props.getBlinds();
    this.props.getPlayers();
    this.props.getVenues();
    this.props.getTournaments();
    this.props.getPrizes();
  }

  handleShowLogoutConfirmModal = () => {
    this.setState({ showLogoutConfirmModal: true });
  };

  // Add method to handle logout confirmation
  handleConfirmLogout = () => {
    clearInterval(this.intervalHandle);
    this.setState({ showLogoutConfirmModal: false });
    this.props.logout(); // Proceed with logout
  };

  // Add method to handle logout cancellation
  handleCancelLogout = () => {
    this.setState({ showLogoutConfirmModal: false }); // Close modal without logging out
  };

  render() {
    const { fixed } = this.state;
    const {
      jwt,
      penultimateEliminated,
      tournamentName,
      inBreak,
      activeRound,
      secondsRemaining,
      roundDuration,
      playTime,
      tournaments,
      leagueTableUrl,
    } = this.props;

    return (
      <Responsive>
        {!jwt ? <Redirect to="/login" /> : null}

        {/* Confirmation Modal for Logout */}
        <Modal
          open={this.state.showLogoutConfirmModal}
          onClose={this.handleCancelLogout}
          className=" delete-confirm-popup "
          style={{
            width: '400px',
            maxWidth: '80%',
          }}
        >
          <Modal.Content
            className=""
            style={{ padding: '20px', textAlign: 'center' }}
          >
            Are you sure you want to log out? This will exit the currently
            running tournament
          </Modal.Content>

          <Modal.Actions
            className=""
            style={{ padding: '10px', textAlign: 'center' }}
          >
            <Button
              className="rounds-action-buttons"
              style={{ margin: '10px' }}
              content="Yes"
              positive
              onClick={this.handleConfirmLogout}
            />
            <Button
              className="rounds-action-buttons"
              onClick={this.handleCancelLogout}
              style={{ margin: '10px' }}
            >
              No
            </Button>
          </Modal.Actions>
        </Modal>
        {/* Existing Modals*/}

        {/* 
        <Modal open={leagueTableUrl !== null}>
          <Modal.Header>Scores Submitted</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header>Scores successfully submitted.</Header>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="black"
              content="View League Table"
              onClick={() => window.open(leagueTableUrl, '_blank')}
            />
            <Button
              content="New Tournament"
              onClick={() => this.props.clearTournament()}
            />
          </Modal.Actions>
        </Modal> */}
        <Modal
          open={penultimateEliminated}
          className="players-modal modal-dimmer-bg-color"
        >
          <Modal.Header className="players-extras-modal-header">
            End Tournament
          </Modal.Header>
          <Modal.Content className="modal-bg-content-container">
            <Modal.Description>
              <p className="set-white-color">
                Are you sure you want to end the tournament?
              </p>
            </Modal.Description>
            <Modal.Actions className="end-game-action-buttons">
              <Button
                content="Yes"
                labelPosition="right"
                icon="checkmark"
                onClick={() => this.props.setGameEnded(true, this.props.ledger)}
                positive
              />
              <Button
                color="black"
                onClick={() => this.props.setPenultimateEliminated(false)}
              >
                No
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal>

        {/* Main content */}
        <TournamentStats />
        <Segment inverted textAlign="center" className="menu-container">
          <Menu
            fixed={fixed ? 'top' : null}
            inverted={!fixed}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <h5 className="tournament-name">{tournamentName}</h5>
              <Menu.Item position="right" className="tournament-menu-items">
                <Players />
                <Blinds />
                <Prizes />
                <Button onClick={this.handleShowLogoutConfirmModal}>
                  Logout
                </Button>
              </Menu.Item>
            </Container>
            <LedgerVisualisation />
          </Menu>
        </Segment>
        <Container fluid={true} className="content-container">
          <Grid padded columns="equal">
            <Grid.Row stretched className="content-primary-container">
              <Grid.Column className="content-primary-container-columns">
                <Segment>
                  <BlindsWidget
                    bigBlind="200"
                    smallBlind="100"
                    ante="10"
                    break={inBreak}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column
                width={8}
                className="content-primary-container-columns"
              >
                <Grid.Row>
                  <Segment>
                    <ClockWidget
                      strokeWidth="22"
                      sqSize="400"
                      round={activeRound + 1}
                      secondsRemaining={secondsRemaining | 0}
                      secondsInRound={roundDuration}
                      secondsPlayedInTotal={playTime}
                      updateRemainingCallback={this.setSecondsRemaining.bind(
                        this
                      )}
                    />
                    <Grid.Row>
                      <ChipsIndicator />
                    </Grid.Row>
                  </Segment>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column className="content-primary-last-column content-primary-container-columns">
                <Segment className="content-primary-last-column-label">
                  <BlindsWidget
                    bigBlind="200"
                    smallBlind="100"
                    ante="10"
                    nextRound={true}
                    break={inBreak}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="content-secondary-container">
              <Grid.Column className="content-secondary-columns-bgColor scroll-in-container internal-box-timer">
                <PotStatsWidget />
              </Grid.Column>
              <Grid.Column
                width={6}
                className="content-secondary-columns-bgColor scroll-in-container internal-box-timer"
              >
                <TournamentPlayersWidget />
              </Grid.Column>
              <Grid.Column className="content-secondary-columns-bgColor scroll-in-container internal-box-timer">
                <PrizePoolWidget />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <TournamentSelector
          tournaments={tournaments}
          logout={this.props.logout}
        ></TournamentSelector>
      </Responsive>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.user.jwt,
    activeRound: state.game.tournament.activeRound,
    inPlay: state.game.tournament.inPlay,
    inBreak: state.game.tournament.inBreak,
    play: state.game.tournament.play,
    penultimateEliminated: state.game.tournament.penultimateEliminated,
    tournamentName: state.game.tournament.details.name,
    roundDuration:
      state.game.tournament.activeBlindStructure.structure[
        state.game.tournament.activeRound
      ].dur,
    secondsRemaining: state.game.tournament.secondsRemaining,
    tournaments: state.game.tournaments,
    tournament: state.game.tournament,
    ledger: state.ledger,
    rounds: state.game.tournament.activeBlindStructure,
    playTime: state.game.tournament.playTime,
    tournamentPlayers: state.game.tournament.players,
    bustPlayers: state.game.tournament.bust,
    leaguePlayers: state.game.players,
    leagueTableUrl: state.game.leagueTableUrl,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBlinds: blindsActions.getBlinds,
      getPlayers: playersActions.getPlayers,
      getVenues: venuesActions.getVenues,
      getTournaments: tournamentsActions.getTournaments,
      getPrizes: prizesActions.getPrizes,
      logout: userActions.logout,
      backRound: tournamentsActions.backRound,
      forwardRound: tournamentsActions.forwardRound,
      tick: timerActions.tick,
      setPenultimateEliminated: tournamentsActions.setPenultimateEliminated,
      setSecondsRemaining: timerActions.setSecondsRemaining,
      setGameEnded: tournamentsActions.setGameEnded,
      clearTournament: tournamentsActions.clearTournament,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
