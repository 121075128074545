export const tournamentsConstants = {
  CREATE_TOURNAMENT_SUCCESS: 'CREATE_TOURNAMENT_SUCCESS',
  CREATE_TOURNAMENT_FAILURE: 'CREATE_TOURNAMENT_FAILURE',

  GET_TOURNAMENTS_SUCCESS: 'GET_TOURNAMENTS_SUCCESS',
  GET_TOURNAMENTS_FAILURE: 'GET_TOURNAMENTS_FAILURE',

  GET_TOURNAMENT_DETAILS_SUCCESS: 'GET_TOURNAMENT_DETAILS_SUCCESS',
  GET_TOURNAMENT_DETAILS_FAILURE: 'GET_TOURNAMENT_DETAILS_FAILURE',

  ADD_PLAYER_TO_TOURNAMENT_SUCCESS: 'ADD_PLAYER_TO_TOURNAMENT_SUCCESS',
  ADD_PLAYER_TO_TOURNAMENT_FAILURE: 'ADD_PLAYER_TO_TOURNAMENT_FAILURE',

  REMOVE_PLAYER_FROM_TOURNAMENT_SUCCESS:
    'REMOVE_PLAYER_FROM_TOURNAMENT_SUCCESS',
  REMOVE_PLAYER_FROM_TOURNAMENT_FAILURE:
    'REMOVE_PLAYER_FROM_TOURNAMENT_FAILURE',

  BACK_ROUND_SUCCESS: 'BACK_ROUND_SUCCESS',
  BACK_ROUND_FAILURE: 'BACK_ROUND_FAILURE',

  FORWARD_ROUND_SUCCESS: 'FORWARD_ROUND_SUCCESS',
  FORWARD_ROUND_FAILURE: 'FORWARD_ROUND_FAILURE',

  SET_TOURNAMENT_NAME_SUCCESS: 'SET_TOURNAMENT_NAME_SUCCESS',
  SET_TOURNAMENT_DATE_SUCCESS: 'SET_TOURNAMENT_DATE_SUCCESS',
  SET_TOURNAMENT_VENUE_SUCCESS: 'SET_TOURNAMENT_VENUE_SUCCESS',

  SET_PENULTIMATE_ELIMINATED_SUCCESS: 'SET_PENULTIMATE_ELIMINATED_SUCCESS',

  SET_TOURNAMENT_FINAL_STATS_SUCCESS: 'SET_TOURNAMENT_FINAL_STATS_SUCCESS',

  START_TOURNAMENT_SUCCESS: 'START_TOURNAMENT_SUCCESS',

  SET_GAME_ENDED_SUCCESS: 'SET_GAME_ENDED_SUCCESS',

  CLEAR_TOURNAMENT_SUCCESS: 'CLEAR_TOURNAMENT_SUCCESS',
  RESET_TOURNAMENT_STATE: 'RESET_TOURNAMENT_STATE',
};
