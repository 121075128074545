export const TOKEN_KEY = "jwt";

export const saveJWT = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getJWT = () => {
  return `Bearer ` + localStorage.getItem(TOKEN_KEY);
};

export const removeJWT = () => {
  localStorage.removeItem(TOKEN_KEY);
};
