import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Grid, Popup, Dropdown } from 'semantic-ui-react';
import Round from './Round';
import { bindActionCreators } from 'redux';
import { blindsActions } from '../actions/blinds.actions';

class Blinds extends Modal {
  constructor(props) {
    super(props);
    this.state = {
      saveConfirmOpen: false,
      name: null,
      deleteOpen: false,
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteOpen = this.deleteOpen.bind(this);
    this.deleteClose = this.deleteClose.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  generateRows() {
    if (this.props.blinds.length > 0) {
      return this.props.activeBlindStructure.structure.map((blind, idx) => (
        <Round
          key={blind.no}
          roundMinutes={blind.dur}
          smallBlind={blind.sb}
          bigBlind={blind.bb}
          ante={blind.ante}
          break={blind.break}
          idx={idx}
          roundNumber={blind.no}
        />
      ));
    }
  }

  handleDropdownChange(event, data) {
    const selectedId = data.value;
    this.setState({ selectedBlindId: selectedId }, () => {
      this.props.selectBlindStruct(selectedId);
    });
  }

  handleOpen() {
    this.setState({ saveConfirmOpen: true });
  }

  handleClose() {
    this.setState({ saveConfirmOpen: false });
  }

  deleteOpen() {
    this.setState({ deleteOpen: true });
  }

  deleteClose() {
    this.setState({ deleteOpen: false });
  }

  render() {
    const blindOptions = this.props.blinds.map((blind) => ({
      key: blind.id,
      text: blind.name,
      value: blind.id,
    }));

    return (
      <>
        <Modal
          closeIcon
          trigger={<Button>Blinds</Button>}
          size="fullscreen"
          centered={false}
        >
          <div className="modal-dimmer-bg-color modal-rounds-screen">
            <Modal.Header className="round-header">Blinds Manager</Modal.Header>
            <Modal.Content>
              <div className="round-buttons-container">
                <div>
                  <Dropdown
                    className="players-table-dropdown"
                    selection
                    search
                    options={blindOptions}
                    onChange={this.handleDropdownChange}
                    value={
                      this.state.selectedBlindId ||
                      `${this?.props?.blinds[0]?.id}`
                    }
                    placeholder="Select a blind structure"
                  />
                  {/*<b>
                  {this.props.activeBlindIdx} -{' '}
                  {this.props.activeBlindStructure.name}
                  {this.props.activeBlindStructure.default === true
                    ? "is default"
                    : "custom"}
                </b>*/}
                </div>

                <div>
                  <button className="ui button rounds-action-buttons">
                    Calculate
                  </button>
                  <Popup
                    trigger={
                      <button
                        className="ui button rounds-action-buttons"
                        onClick={() => {
                          this.setState({ saveConfirmOpen: true });
                        }}
                      >
                        Save
                      </button>
                    }
                    on="click"
                    open={this.state.saveConfirmOpen}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    position="bottom right"
                    className="save-blinds-popup"
                  >
                    <Popup.Header className="save-blinds-popup-header">
                      <span>Save Blinds</span>
                      <Icon
                        className="close"
                        link
                        icon="close"
                        onClick={() =>
                          this.setState({ saveConfirmOpen: false })
                        }
                      />
                    </Popup.Header>
                    <Popup.Content>
                      <p>Please give this blind structure a name.</p>
                      <div className="ui input">
                        <input
                          type="text"
                          placeholder="Name..."
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </div>
                      <div className="save-blinds-popup-button">
                        <button
                          className="ui button rounds-action-buttons"
                          onClick={async () => {
                            if (this.state.name === null) {
                              return;
                            }
                            try {
                              const newBlinds = await this.props.addBlinds(
                                this.state.name,
                                this.props.activeBlindStructure.structure
                              );

                              await new Promise((resolve) => {
                                setTimeout(() => {
                                  resolve(); // Resolve the promise after 0.5 seconds
                                }, 500);
                              });

                              await this.props.getBlinds(); // Call this only after addBlinds is successful
                              this.setState({
                                selectedBlindId: newBlinds?.id,
                                saveConfirmOpen: false,
                              });
                            } catch (error) {
                              console.error('Error adding blinds:', error); // Handle the error appropriately
                            }
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </Popup.Content>
                  </Popup>

                  <span>
                    <button
                      className="ui button rounds-action-buttons"
                      onClick={this.deleteOpen}
                    >
                      Delete
                    </button>
                  </span>
                </div>
              </div>
              <Grid divided="vertically" className="modal-bg-content-container">
                <Grid.Row className="modal-rounds-column-headers">
                  <Grid.Column className="two wide column round-column-header">
                    <h2>Round</h2>
                  </Grid.Column>
                  <Grid.Column className="three wide column">
                    <h2>Small Blind</h2>
                  </Grid.Column>
                  <Grid.Column className="three wide column">
                    <h2>Big Blind</h2>
                  </Grid.Column>
                  <Grid.Column className="three wide column">
                    <h2>Ante</h2>
                  </Grid.Column>
                  <Grid.Column className="three wide column">
                    <h2>Duration (minutes)</h2>
                  </Grid.Column>
                  <Grid.Column className="two wide column">
                    <h2>Break</h2>
                  </Grid.Column>
                </Grid.Row>
                <div className="modal-round-content-container">
                  {this.generateRows()}{' '}
                </div>
              </Grid>
            </Modal.Content>

            <Modal
              open={this.state.deleteOpen}
              onClose={this.deleteClose}
              className="delete-confirm-popup "
              style={{
                width: '400px',
                maxWidth: '80%',
                marginTop: '20px',
              }}
              centered
            >
              <Modal.Content
                className=""
                style={{ padding: '20px', textAlign: 'center' }}
              >
                {`Are you sure you want to delete the blind structure "${this?.props?.activeBlindStructure?.name}"?`}
              </Modal.Content>

              <Modal.Actions
                className=""
                style={{ padding: '10px', textAlign: 'center' }}
              >
                <Button
                  className="rounds-action-buttons"
                  style={{ margin: '10px' }}
                  content="Yes"
                  positive
                  onClick={async () => {
                    try {
                      await this.props.deleteBlinds(
                        this.props.activeBlindStructure.id
                      );
                      await this.props.getBlinds();
                      this.deleteClose();
                    } catch (error) {
                      console.error('Error deleting blinds:', error);
                    }
                  }}
                />
                <Button
                  className="rounds-action-buttons"
                  onClick={this.deleteClose}
                  style={{ margin: '10px' }}
                >
                  No
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rounds: state.game.tournament.rounds,
    inPlay: state.game.tournament.inPlay,
    activeRound: state.activeRound,
    players: state.players,
    blinds: state.game.blinds,
    activeBlindStructure: state.game.tournament.activeBlindStructure,
    activeBlindIdx: state.game.tournament.activeBlindIdx,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBlinds: blindsActions.getBlinds,
      addBlinds: blindsActions.addBlinds,
      deleteBlinds: blindsActions.deleteBlinds,
      prevBlindStruct: blindsActions.prevBlindStruct,
      nextBlindStruct: blindsActions.nextBlindStruct,
      selectBlindStruct: blindsActions.selectBlindStruct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Blinds);
