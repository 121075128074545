import React from "react";

import { Button, Form, Grid, Icon, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { addToLedger } from "../actions/ledger.actions";
import { playersActions } from "../actions/players.actions";

class PlayerExtras extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      cash: this.props.buyInCash,
      chips: this.props.buyInChips,
      open: false,
    };
  }

  handleSubmit(e) {
    if (this.props.rebuy) {
      this.props.playerRebuy(
        parseInt(this.props.playerId),
        this.state.chips,
        this.state.cash
      );
    } else {
      this.props.playerAddon(
        parseInt(this.props.playerId),
        this.state.chips,
        this.state.cash
      );
    }

    if (this.props.rebuy === true) {
      this.props.unbustPlayer(this.props.playerId);
    }

    this.setState({ open: false });
  }

  close = () => this.setState({ open: false });

  render() {
    const { display, icon, playerName, playerPhoto, title } = this.props;

    return (
      <Modal
        open={this.state.open}
        closeIcon
        onClose={this.close}
        trigger={
          <Button
            icon
            floated="right"
            onClick={() => this.setState({ open: true })}
            style={{
              display: "inline-block",
              visibility: display ? "visible" : "hidden",
            }}
          >
            <Icon name={icon} />
          </Button>
        }
        className="players-modal modal-dimmer-bg-color"
      >
        <Modal.Header className="players-extras-modal-header">
          {title} - {playerName}
        </Modal.Header>
        <Modal.Content image className="modal-bg-content-container">
          <Grid columns="equal">
            <Grid.Row columns="equal">
              <Grid.Column>
                <Image src={playerPhoto} circular size="small" />
              </Grid.Column>
              <Grid.Column>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field>
                    <label className="player-extras-dialog-label">Cash</label>
                    <input
                      placeholder="100"
                      value={this.state.cash}
                      onChange={(event) => {
                        this.setState({ cash: parseInt(event.target.value) });
                      }}
                      type="number"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label className="player-extras-dialog-label">Chips</label>
                    <input
                      placeholder="100"
                      value={this.state.chips}
                      onChange={(event) => {
                        this.setState({ chips: parseInt(event.target.value) });
                      }}
                      type="number"
                    />
                  </Form.Field>
                  <Button className="standard-buttons" type="submit">
                    {this.props.buyinPlayer ? "Rebuy" : "Add-On" }
                  </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    buyInChips: state.game.tournament.pot.buyInChips,
    buyInCash: state.game.tournament.pot.buyIn,
  };
};
export default connect(mapStateToProps, {
  unbustPlayer: playersActions.unbustPlayer,
  addToLedger: addToLedger,
  playerRebuy: playersActions.playerRebuy,
  playerAddon: playersActions.playerAddon,
})(PlayerExtras);
