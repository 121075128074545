import AuthenticatedEndpoint from '../helpers/api';

function submitStats(payload) {
  return AuthenticatedEndpoint.post(null, payload, {
    params: {
      op: 'finalstats',
    },
  }).then((res) => {
    let data = res.data;

    // Check if the response data is a string
    if (typeof data === 'string') {
      // Split the data based on <br> tag and extract the second part
      const parts = data.split('<br>');
      if (parts.length > 1) {
        // Extract the JSON part after the <br> tag
        const jsonPart = parts[1].trim();
        // Parse the JSON part
        try {
          data = JSON.parse(jsonPart);
          // Return the leaguetableurl
          return data.result.leaguetableurl;
        } catch (error) {
          console.error('Failed to parse JSON:', error);
        }
      } else {
        console.error('Unexpected response format.');
      }
    }
    // Return or handle the case where data is not a string or does not contain expected JSON
    return null;
  });
}

export const statsServices = {
  submitStats,
};
