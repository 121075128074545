import { statsServices } from '../services/stats.service';
import { statsConstants } from '../constants/stats.constants';
import { tournamentsActions } from './tournaments.actions';
import { venuesActions } from './venues.actions';
import { blindsActions } from './blinds.actions';
import { playersActions } from './players.actions';

function submitStats(payload) {
  return (dispatch) => {
    const promise = statsServices.submitStats(payload);

    promise.then(
      (result) => {
        dispatch(success(result));
        dispatch(tournamentsActions.getTournaments());
        dispatch(venuesActions.getVenues());
        dispatch(blindsActions.getBlinds());
        dispatch(playersActions.getPlayers());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(result) {
    return { type: statsConstants.SUBMIT_STATS_SUCCESS, result };
  }

  function failure(error) {
    return { type: statsConstants.SUBMIT_STATS_FAILURE, error };
  }
}

export const statsActions = {
  submitStats,
};
