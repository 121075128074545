export const ledgerTotals = (
  ledger,
  playerId = null,
  transactionType = null
) => {
  if (playerId !== null) {
    ledger = ledger.filter((o) => o.playerId === parseInt(playerId));
  }

  if (transactionType !== null) {
    ledger = ledger.filter((o) => o.transactionType === transactionType);
  }

  const totals = {
    totalChips: ledger.reduce((x, y) => {
      if (y["transactionType"] === "credit") {
        return parseInt(x) + parseInt(y["chipAmount"]);
      } else if (y["transactionType"] === "debit") {
        return parseInt(x) - parseInt(y["chipAmount"]);
      } else {
        return parseInt(x);
      }
    }, 0),
    totalCash: ledger.reduce((x, y) => {
      if (y["transactionType"] === "credit") {
        return parseInt(x) + parseInt(y["cashAmount"]);
      } else if (y["transactionType"] === "debit") {
        return parseInt(x) - parseInt(y["cashAmount"]);
      } else {
        return parseInt(x);
      }
    }, 0),
  };
  return totals;
};

export const getPlayerBuyIn = (ledger, playerId) => {
  for (const element of ledger) {
    if (element.playerId === parseInt(playerId) && element.type === "BUYIN") {
      return element;
    }
  }
};

function search(id, myArray) {
  for (const element of myArray) {
    if (element.id === id.toString()) {
      return element;
    }
  }
}

export const getPlayerInBustOrder = (
  ledger,
  players,
  bustPlayers,
  leaguePlayers
) => {
  players = players.map((x) => parseInt(x));
  bustPlayers = bustPlayers.map((x) => parseInt(x));

  const bustLedger = ledger.filter((o) => o.type === "BUST_PLAYER");
  const sorted = bustLedger
    .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
    .reverse();

  let bustOrder = [];

  sorted.forEach((e) => {
    if (!bustOrder.includes(e.playerId) && bustPlayers.includes(e.playerId)) {
      bustOrder.push(e.playerId);
    }
  });

  const diff = players.filter((x) => !bustOrder.includes(x));
  if (diff.length === 1) {
    bustOrder.unshift(diff[0]);
  }

  let bustPlayerObjects = [];

  bustOrder.forEach((e) => {
    bustPlayerObjects.push(search(e, leaguePlayers));
  });

  bustPlayerObjects.forEach((e) => {
    e.cash = ledgerTotals(ledger, e.id).totalCash;
    e.points = 0;
  });

  return bustPlayerObjects;
};

export const startTournament = () => {
  return {
    type: "START_TOURNAMENT",
    playerId: null,
    transactionType: "noop",
    timestamp: Date.now(),
    chipAmount: 0,
    cashAmount: 0,
    round: 0,
  };
};

export const buyinPlayer = (player, chips, cash, transactionType) => {
  return {
    type: "BUYIN",
    playerId: player,
    transactionType: transactionType,
    timestamp: Date.now(),
    chipAmount: chips,
    cashAmount: cash,
    round: 0,
  };
};

export const playerRebuy = (player, chips, cash, round) => {
  return {
    type: "REBUY",
    playerId: player,
    transactionType: "credit",
    timestamp: Date.now(),
    chipAmount: chips,
    cashAmount: cash,
    round: round,
  };
};

export const playerAddon = (player, chips, cash, round) => {
  return {
    type: "ADDON",
    playerId: player,
    transactionType: "credit",
    timestamp: Date.now(),
    chipAmount: chips,
    cashAmount: cash,
    round: round,
  };
};

export const bustPlayer = (playerId, round) => {
  return {
    type: "BUST_PLAYER",
    playerId: playerId,
    transactionType: "noop",
    timestamp: Date.now(),
    chipAmount: 0,
    cashAmount: 0,
    round: round,
  };
};

export const unbustPlayer = (playerId, round) => {
  return {
    type: "UNBUST_PLAYER",
    playerId: playerId,
    transactionType: "noop",
    timestamp: Date.now(),
    chipAmount: 0,
    cashAmount: 0,
    round: round,
  };
};
