export const blindsConstants = {
  ADD_BLINDS_SUCCESS: 'ADD_BLINDS_SUCCESS',
  ADD_BLINDS_FAILURE: 'ADD_BLINDS_FAILURE',

  UPDATE_BLINDS_SUCCESS: 'UPDATE_BLINDS_SUCCESS',

  DELETE_BLINDS_SUCCESS: 'DELETE_BLINDS_SUCCESS',
  DELETE_BLINDS_FAILURE: 'DELETE_BLINDS_FAILURE',

  GET_BLINDS_SUCCESS: 'GET_BLINDS_SUCCESS',
  GET_BLINDS_FAILURE: 'GET_BLINDS_FAILURE',

  PREV_BLIND_STRUCT_SUCCESS: 'PREV_BLIND_STRUCT_SUCCESS',
  NEXT_BLIND_STRUCT_SUCCESS: 'NEXT_BLIND_STRUCT_SUCCESS',
  SELECT_BLIND_STRUCT: 'SELECT_BLIND_STRUCT',
};
