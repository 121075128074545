import { timerConstants } from "../constants/timer.constants";

function togglePlayState() {
  return {
    type: timerConstants.TOGGLE_TIMER_STATE_SUCCESS,
  };
}

function tick() {
  return {
    type: timerConstants.TICK_SUCCESS,
  };
}

function setSecondsRemaining(secondsRemaining) {
  return {
    type: timerConstants.SET_SECONDS_REMAINING_SUCCESS,
    secondsRemaining: secondsRemaining,
  };
}

export const timerActions = {
  togglePlayState,
  tick,
  setSecondsRemaining,
};
