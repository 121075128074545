export const calculateTotalAllocatedCash = (prizeAllocations) => {
  const end = 10;
  let totalAllocatedCash = 0;

  for (let i = 1; i <= end; i++) {
    if (prizeAllocations[i]?.cash) {
      totalAllocatedCash += prizeAllocations[i].cash;
    }
  }

  return totalAllocatedCash;
};

export const calculateTotalAllocatedPercentage = (prizeAllocations) => {
  const end = 10;
  let totalAllocatedPercentage = 0;

  for (let i = 1; i <= end; i++) {
    if (prizeAllocations[i]?.percentage) {
      totalAllocatedPercentage += prizeAllocations[i].percentage;
    }
  }

  return totalAllocatedPercentage;
};

export const ordinalSuffixOf = (i) => {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const calculatePrizeWithRounding = (
  prizePercentage,
  totalCashInPot,
  cashRounding
) => {
  return (
    Math.floor(((prizePercentage / 100) * totalCashInPot) / cashRounding) *
    cashRounding
  );
};
