import { prizesServices } from '../services/prizes.service';
import { prizesConstants } from '../constants/prizes.constants';

function getPrizes() {
  return (dispatch) => {
    const promise = prizesServices.getPrizes();

    promise.then(
      (prizes) => {
        dispatch(success(prizes));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(prizes) {
    return { type: prizesConstants.GET_PRIZES_SUCCESS, prizes };
  }

  function failure(error) {
    return { type: prizesConstants.GET_PRIZES_FAILURE, error };
  }
}

function addPrizesStructure(
  prizesStructureName,
  cashRounding,
  prizesStructure
) {
  return (dispatch) => {
    return prizesServices
      .addPrizesStructure(prizesStructureName, cashRounding, prizesStructure) // Return this promise
      .then(
        (response) => {
          // Since response is already an object, we don't need to parse it
          if (response.success) {
            dispatch(success(response.result)); // Dispatch the result
            return response.result; // Return the result object
          } else {
            throw new Error(response.error || 'Error adding prizes');
          }
        },
        (error) => {
          dispatch(failure(error));
          throw error; // Throw the error so it can be caught in the component
        }
      );
  };

  function success(prizes) {
    return {
      type: prizesConstants.ADD_PRIZES_STRUCTURE_SUCCESS,
      prizes: { ...prizes },
    };
  }

  function failure(error) {
    return { type: prizesConstants.ADD_PRIZES_STRUCTURE_FAILURE, error };
  }
}

function deletePrizes(prizeId) {
  return (dispatch) => {
    const promise = prizesServices.deletePrizes(prizeId);

    promise.then(
      () => {
        dispatch(success(prizeId));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(prizeId) {
    return { type: prizesConstants.DELETE_PRIZES_SUCCESS, prizeId };
  }

  function failure(error) {
    return { type: prizesConstants.DELETE_PRIZES_FAILURE, error };
  }
}

export const prizesActions = {
  getPrizes,
  addPrizesStructure,
  deletePrizes,
};
