import { venuesServices } from "../services/venues.service";
import { venuesConstants } from "../constants/venues.constants";

function createVenue(venuename) {
  return (dispatch) => {
    const promise = venuesServices.createVenue(venuename);

    promise.then(
      (venues) => {
        dispatch(success(venues));
        dispatch(getVenues());
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(venues) {
    return { type: venuesConstants.CREATE_VENUE_SUCCESS, venues };
  }

  function failure(error) {
    return { type: venuesConstants.CREATE_VENUE_FAILURE, error };
  }
}

function getVenues() {
  return (dispatch) => {
    const promise = venuesServices.getVenues();

    promise.then(
      (venues) => {
        dispatch(success(venues));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(venues) {
    return { type: venuesConstants.GET_VENUES_SUCCESS, venues };
  }

  function failure(error) {
    return { type: venuesConstants.GET_VENUES_FAILURE, error };
  }
}

export const venuesActions = {
  createVenue,
  getVenues,
};
