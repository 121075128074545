import { playersServices } from "../services/players.service";
import { playersConstants } from "../constants/players.constants";

function getPlayers() {
  return (dispatch) => {
    const promise = playersServices.getPlayers();

    promise.then(
      (blinds) => {
        dispatch(success(blinds));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(players) {
    return { type: playersConstants.GET_PLAYERS_SUCCESS, players };
  }

  function failure(error) {
    return { type: playersConstants.GET_PLAYERS_FAILURE, error };
  }
}

function bustPlayer(playerId) {
  return {
    type: playersConstants.BUST_PLAYER_SUCCESS,
    playerId: playerId,
  };
}

function unbustPlayer(playerId) {
  return {
    type: playersConstants.UNBUST_PLAYER_SUCCESS,
    playerId: playerId,
  };
}

function playerRebuy(playerId, chips, cash) {
  return {
    type: playersConstants.REBUY_PLAYER_SUCCESS,
    playerId: playerId,
    cash: cash,
    chips: chips,
  };
}

function playerAddon(playerId, chips, cash) {
  return {
    type: playersConstants.ADDON_PLAYER_SUCCESS,
    playerId: playerId,
    cash: cash,
    chips: chips,
  };
}

function setDefaultBuyin(chips, cash){
  return {
    type: playersConstants.SET_DEFAULT_BUYIN,
    chips: chips,
    cash: cash,
  }
}

export const playersActions = {
  getPlayers,
  bustPlayer,
  unbustPlayer,
  playerRebuy,
  playerAddon,
  setDefaultBuyin,
};
