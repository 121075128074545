import React, { createRef } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import { DataSet, Timeline } from 'vis';
import { connect } from 'react-redux';

class VisTimeline extends React.Component {
  constructor(props) {
    super(props);
    this.timeline = {};
    this.appRef = createRef();
  }

  formatLedger(ledger) {
    let dataSet = [];

    ledger.forEach((item, idx) => {
      const content = `
        <div>
          <b>${item.type} (${item.transactionType}) - ${item.playerId}</b>
          cash: ${item.cashAmount}
          chips: ${item.chipAmount}
        </div>
      `;
      const style =
        item.transactionType === 'credit'
          ? 'background-color: AliceBlue;'
          : item.transactionType === 'debit'
          ? 'background-color: Aquamarine;'
          : 'background-color: Coral;';

      dataSet.push({
        id: idx,
        title: item.type,
        content: content,
        group: item.transactionType,
        style: style,
        start: item.timestamp,
      });
    });

    return new DataSet(dataSet);
  }

  componentDidMount() {
    this.timeline = new Timeline(
      this.appRef.current,
      this.formatLedger(this.props.ledger),
      {}
    );
  }

  render() {
    return <div ref={this.appRef} />;
  }
}

class LedgerVisualisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }
  closeModal = () => {
    this.setState({ showModal: this.state.error });
  };

  render() {
    const { showModal } = this.state;

    return (
      <Modal
        closeIcon
        trigger={
          <Button
            className="hidden-button"
            onClick={() => this.setState({ showModal: true })}
          ></Button>
        }
        size="fullscreen"
        centered={false}
        open={showModal}
        onClose={this.closeModal}
      >
        <Modal.Header>Ledger Visualisation</Modal.Header>
        <Modal.Content>
          <VisTimeline ledger={this.props.ledger} />
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ledger: state.ledger,
  };
};

export default connect(mapStateToProps, {})(LedgerVisualisation);
