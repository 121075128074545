import AuthenticatedEndpoint from '../helpers/api';

function getCountries() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getcountries`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

export const countriesServices = {
  getCountries,
};
