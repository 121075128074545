export const potConstants = {
  BUYIN_SUCCESS: "ADD_BLINDS_SUCCESS",
  BUYIN_FAILURE: "ADD_BLINDS_FAILURE",

  UPDATE_CASH_SUCCESS: "ADD_BLINDS_SUCCESS",
  UPDATE_CASH_FAILURE: "ADD_BLINDS_FAILURE",

  UPDATE_CHIPS_SUCCESS: "UPDATE_CHIPS_SUCCESS",
  UPDATE_CHIPS_FAILURE: "UPDATE_CHIPS_FAILURE",

  ADD_CHIPS_SUCCESS: "ADD_CHIPS_SUCCESS",
  ADD_CHIPS_FAILURE: "ADD_CHIPS_SUCCESS",

  DELETE_BLINDS_SUCCESS: "DELETE_BLINDS_SUCCESS",
  DELETE_BLINDS_FAILURE: "DELETE_BLINDS_FAILURE",

  GET_BLINDS_SUCCESS: "GET_BLINDS_SUCCESS",
  GET_BLINDS_FAILURE: "GET_BLINDS_FAILURE",

  UPDATE_PRIZE_ALLOCATIONS_SUCCESS: "UPDATE_PRIZE_ALLOCATIONS_SUCCESS",
  UPDATE_PRIZE_ALLOCATIONS_FAILURE: "UPDATE_PRIZE_ALLOCATIONS_FAILURE",
};
