import { userConstants } from "../constants/user.constants";
import { TOKEN_KEY } from "../helpers/auth";

const initialState = {
  jwt: localStorage.getItem(TOKEN_KEY),
  loginError: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loginError: null,
        jwt: action.jwt,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.error,
      };

    case userConstants.LOGOUT:
      return { ...initialState, jwt: null };

    default:
      return state;
  }
};
