import { pointsServices } from '../services/points.service';
import { pointsConstants } from '../constants/points.constants';

function getPoints(playersLength) {
  return (dispatch) => {
    const promise = pointsServices.getPoints(playersLength);

    promise.then(
      (points) => {
        dispatch(success(points));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(points) {
    return { type: pointsConstants.GET_POINTS_SUCCESS, points };
  }

  function failure(error) {
    return { type: pointsConstants.GET_POINTS_FAILURE, error };
  }
}

export const pointsActions = {
  getPoints,
};
