import React from 'react';
import { Modal, Grid, Form, Button } from 'semantic-ui-react';

import { countriesServices } from '../services/countries.service';
import { playersServices } from '../services/players.service';

class CreatePlayer extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state in the class component
    this.state = {
      countries: {}, // Country object with 3-letter codes as keys and long names as values
      playername: '',
      nickname: '',
      team: '',
      email: '',
      profile: '',
      country: '', // This will store the selected 3-letter code
      loading: false,
      error: null,
      showErrorModal: false, // New state to control error modal
      errorMessage: '',
    };

    this._isMounted = false; // Flag to track if the component is mounted
  }

  // Method to fetch countries
  fetchCountries = () => {
    this.setState({ loading: true });

    countriesServices
      .getCountries()
      .then((data) => {
        if (this._isMounted) {
          this.setState({ countries: data, loading: false });
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          this.setState({ error: 'Failed to fetch countries', loading: false });
        }
      });
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchCountries();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { playername, nickname, team, email, profile, country } = this.state;

    // Call the createPlayer service
    playersServices
      .createPlayer(playername, nickname, team, email, profile, country)
      .then(() => {
        this.setState({
          playername: '',
          nickname: '',
          team: '',
          email: '',
          profile: '',
          country: '',
        });
        this.props.onClose();
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.error ||
          'A player with the same name already exists. ';

        this.setState({
          showErrorModal: true, // Show error modal
          errorMessage, // Set the error message
        });
      });
  };

  render() {
    const { open, onClose } = this.props;
    const {
      countries,
      playername,
      nickname,
      team,
      email,
      profile,
      country,
      loading,
      error,
    } = this.state;

    return (
      <>
        <Modal
          open={open}
          onClose={onClose}
          closeIcon
          size="fullscreen"
          centered={false}
          className="players-modal modal-dimmer-bg-color"
        >
          <Modal.Header className="players-modal-header">
            Create a New Player
          </Modal.Header>
          <Modal.Content className="modal-bg-content-container">
            {loading ? (
              <p>Loading countries...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <Form onSubmit={this.handleSubmit}>
                <Grid columns="equal">
                  <Grid.Row columns="equal">
                    <Grid.Column columns="equal">
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Name
                        </label>
                        <input
                          name="playername"
                          value={playername}
                          onChange={(e) =>
                            this.setState({ playername: e.target.value })
                          }
                          placeholder="Enter player name"
                          maxLength="150"
                          required
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column columns="equal">
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Nickname
                        </label>
                        <input
                          name="nickname"
                          value={nickname}
                          onChange={(e) =>
                            this.setState({ nickname: e.target.value })
                          }
                          placeholder="Enter nickname"
                          maxLength="50"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column columns="equal">
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Team
                        </label>
                        <input
                          name="team"
                          value={team}
                          onChange={(e) =>
                            this.setState({ team: e.target.value })
                          }
                          placeholder="Enter team name"
                          maxLength="150"
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column columns="equal">
                      <Form.Field>
                        <label className="players-modal-secodary-headers">
                          Email
                        </label>
                        <input
                          name="email"
                          type="email"
                          value={email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          placeholder="Enter email address"
                          maxLength="150"
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Form.Field>
                  <label className="players-modal-secodary-headers nationality-header">
                    Nationality
                  </label>
                  <Form.Dropdown
                    name="country" // Make sure this is set
                    value={country}
                    search
                    onChange={this.handleChange}
                    placeholder="Select country"
                    fluid
                    selection
                    options={Object.keys(countries).map((code) => ({
                      key: code,
                      text: countries[code],
                      value: code, // Use the 3-letter country code as the value
                    }))}
                  />
                </Form.Field>
                <Form.Field>
                  <label className="players-modal-secodary-headers profile-header">
                    Profile
                  </label>
                  <textarea
                    name="profile"
                    value={profile}
                    onChange={(e) => this.setState({ profile: e.target.value })}
                    placeholder="Enter profile details"
                  />
                </Form.Field>
                <div className="add-players-button">
                  <Button type="submit">Create player</Button>
                </div>
              </Form>
            )}
          </Modal.Content>
          <Modal
            open={this.state.showErrorModal}
            onClose={() => this.setState({ showErrorModal: false })}
            size="small"
            style={{
              width: '350px',
              maxWidth: '80%',
              marginTop: '20px',
            }}
            className="delete-confirm-popup"
            centered
          >
            <Modal.Content>
              <p>{this.state.errorMessage}</p>
            </Modal.Content>
            <Modal.Actions>
              <div className="error-modal-button-container">
                <Button
                  className="rounds-action-buttons"
                  onClick={() => this.setState({ showErrorModal: false })}
                >
                  OK
                </Button>
              </div>
            </Modal.Actions>
          </Modal>
        </Modal>
      </>
    );
  }
}

export default CreatePlayer;
