import React from "react";

import { List } from "semantic-ui-react";

import { connect } from "react-redux";
import TournamentPlayersWidgerPlayer from "./TournamentPlayersWidgerPlayer";

class TournamentPlayersWidget extends React.Component {
  fetchPlayers(players) {
    return {
      type: "FETCH_PLAYERS",
      players,
    };
  }

  idToPlayer(id) {
    let playerMap = {};
    this.props.leaguePlayers.forEach(function (item, _) {
      const _id = item.id;

      playerMap[_id] = item;
    });

    return playerMap[id];
  }

  playerList() {
    return this.props.tournamentPlayers.map((playerId) => {
      const player = this.idToPlayer(playerId);
      return (
        <TournamentPlayersWidgerPlayer
          name={player.name}
          key={player.id}
          id={player.id}
          photo={player.photo}
        />
      );
    });
  }

  render() {
    return (
      <List className="scroll-internal-players-list">{this.playerList()}</List>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leaguePlayers: state.game.players,
    tournamentPlayers: state.game.tournament.players,
  };
};

export default connect(mapStateToProps, {})(TournamentPlayersWidget);
