import AuthenticatedEndpoint from '../helpers/api';

function getPoints(playersLength) {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: 'getpoints',
      players: playersLength,
    },
  }).then((res) => {
    return res.data.result;
  });
}

export const pointsServices = {
  getPoints,
};
