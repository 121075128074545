import { getPlayerInBustOrder } from '../helpers/ledger';
import { blindsConstants } from '../constants/blinds.constants';
import { playersConstants } from '../constants/players.constants';
import { potConstants } from '../constants/pot.constants';
import { venuesConstants } from '../constants/venues.constants';
import { tournamentsConstants } from '../constants/tournaments.constants';
import { statsConstants } from '../constants/stats.constants';
import { pointsConstants } from '../constants/points.constants';
import { timerConstants } from '../constants/timer.constants';
import { prizesConstants } from '../constants/prizes.constants';

const pot = {
  startingStack: 0,
  buyInChips: 200,
  buyIn: 20,
  cashMode: true,
  cashRounding: 1,
  prizeAllocation: {
    1: { cash: 0, percentage: 0 },
    2: { cash: 0, percentage: 0 },
    3: { cash: 0, percentage: 0 },
    4: { cash: 0, percentage: 0 },
    5: { cash: 0, percentage: 0 },
    6: { cash: 0, percentage: 0 },
    7: { cash: 0, percentage: 0 },
    8: { cash: 0, percentage: 0 },
    9: { cash: 0, percentage: 0 },
    10: { cash: 0, percentage: 0 },
  },
  selectedPrizeStructureId: null,
};

const initialState = {
  tournaments: [],
  players: [],
  blinds: [],
  venues: [],
  activeTournamentId: null,
  prizes: [],
  leagueTableUrl: null,
  tournament: {
    details: {},
    playTime: 0,
    timeOut: false,
    timeRemaining: 0,
    inPlay: false,
    play: false,
    penultimateEliminated: false,
    gameEnded: false,
    ledger: [],
    players: [],
    bust: [],
    rounds: [],
    pot: pot,
    finalStats: [],
    inBreak: false,
    activeRound: 0,
    activeBlindIdx: 0,
    activeBlind: {},
    activeBlindStructure: {
      structure: [{ dur: 0 }],
    },
  },
};

export const gameReducer = (state = initialState, action) => {
  let prizeAllocation;

  switch (action.type) {
    case tournamentsConstants.GET_TOURNAMENTS_SUCCESS:
      return {
        ...state,
        tournaments: action.tournaments,
      };
    case tournamentsConstants.SET_TOURNAMENT_NAME_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          details: { ...state.tournament.details, name: action.name },
        },
      };
    case tournamentsConstants.SET_TOURNAMENT_DATE_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          details: { ...state.tournament.details, date: action.date },
        },
      };
    case tournamentsConstants.SET_TOURNAMENT_VENUE_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          details: {
            ...state.tournament.details,
            venue: action.venue,
            venueid: action.venueid,
          },
        },
      };
    case blindsConstants.GET_BLINDS_SUCCESS:
      return {
        ...state,
        blinds: action.blinds,
        tournament: {
          ...state.tournament,
          activeBlindStructure: action.blinds[state.tournament.activeBlindIdx],
          secondsRemaining:
            action.blinds[state.tournament.activeBlindIdx].structure[0].dur,
        },
      };
    case blindsConstants.ADD_BLINDS_SUCCESS:
      return {
        ...state,
        blinds: [...state.blinds, action.blinds],
        tournament: {
          ...state.tournament,

          //activeBlindStructure: action?.blinds,
          activeBlindIdx: state?.blinds?.length,
        },
      };

    case blindsConstants.DELETE_BLINDS_SUCCESS:
      const blinds = state.blinds.filter(function (obj) {
        return obj.id !== action.blindsId;
      });

      const activeBlindIdx =
        state.tournament.activeBlindIdx > 0
          ? state.tournament.activeBlindIdx - 1
          : 0;
      const activeBlindStructure = blinds[activeBlindIdx];

      return {
        ...state,
        blinds: blinds,
        tournament: {
          ...state.tournament,
          activeBlindStructure: activeBlindStructure,
          activeBlindIdx: activeBlindIdx,
        },
      };
    case blindsConstants.UPDATE_BLINDS_SUCCESS:
      const newBlinds = [...state.blinds];
      newBlinds[action.idx] = action.struct;

      return {
        ...state,
        blinds: newBlinds,
        tournament: {
          ...state.tournament,
          activeBlindStructure: action.struct,
        },
      };
    case blindsConstants.PREV_BLIND_STRUCT_SUCCESS:
      const prevBlindStructure =
        state.tournament.activeBlindIdx > 0
          ? state.tournament.activeBlindIdx - 1
          : 0;
      return {
        ...state,
        tournament: {
          ...state.tournament,
          activeBlindIdx: prevBlindStructure,
          activeBlindStructure: action.blinds[prevBlindStructure],
        },
      };
    case blindsConstants.NEXT_BLIND_STRUCT_SUCCESS:
      const nextBlindStructure =
        state.tournament.activeBlindIdx + 1 <= state.blinds.length - 1
          ? state.tournament.activeBlindIdx + 1
          : state.blinds.length - 1;
      return {
        ...state,
        tournament: {
          ...state.tournament,
          activeBlindIdx: nextBlindStructure,
          activeBlindStructure: action.blinds[nextBlindStructure],
        },
      };
    case playersConstants.GET_PLAYERS_SUCCESS:
      return {
        ...state,
        players: action.players,
      };
    case tournamentsConstants.BACK_ROUND_SUCCESS:
      const backRound =
        state.tournament.activeRound > 0 ? state.tournament.activeRound - 1 : 0;
      const backTimeRemaining =
        state.tournament.activeBlindStructure.structure[backRound].dur;

      return {
        ...state,
        tournament: {
          ...state.tournament,
          activeRound: backRound,
          secondsRemaining: backTimeRemaining,
          inBreak:
            state.tournament.activeBlindStructure.structure[backRound].break ===
            1,
        },
      };

    case tournamentsConstants.FORWARD_ROUND_SUCCESS:
      const forwardRound =
        state.tournament.activeRound + 1 <
        state.tournament.activeBlindStructure.structure.length
          ? state.tournament.activeRound + 1
          : state.tournament.activeRound;
      const forwardTimeRemaining =
        state.tournament.activeBlindStructure.structure[forwardRound].dur;

      return {
        ...state,
        tournament: {
          ...state.tournament,
          activeRound: forwardRound,
          secondsRemaining: forwardTimeRemaining,
          inBreak:
            state.tournament.activeBlindStructure.structure[forwardRound]
              .break === 1,
        },
      };
    case timerConstants.TICK_SUCCESS:
      const playTime = state.tournament.playTime + 1;

      let inBreak = state.tournament.inBreak;
      let timeOut = state.tournament.timeOut;
      let activeRound = state.tournament.activeRound;
      let secondsRemaining = state.tournament.secondsRemaining;

      if (!state.tournament.play) {
        // Not in play, don't tick clock.
        return state;
      }

      if (
        secondsRemaining === 0 &&
        activeRound < state.tournament.activeBlindStructure.structure.length - 1
      ) {
        // end of round reached, move forward, reset clock to round duration
        activeRound++;
        secondsRemaining =
          state.tournament.activeBlindStructure.structure[activeRound].dur;
        inBreak =
          state.tournament.activeBlindStructure.structure[activeRound].break;
      } else if (
        secondsRemaining === 0 &&
        activeRound ===
          state.tournament.activeBlindStructure.structure.length - 1
      ) {
        // end of last round reached, keep counting play time but stop countdown and don't attempt to move to next round
        timeOut = true;
      } else {
        // otherwise countdown
        secondsRemaining--;
      }

      return {
        ...state,
        tournament: {
          ...state.tournament,
          secondsRemaining: secondsRemaining,
          playTime: playTime,
          activeRound: activeRound,
          timeOut: timeOut,
          inBreak: inBreak,
        },
      };

    case timerConstants.SET_SECONDS_REMAINING_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          secondsRemaining: action.secondsRemaining,
        },
      };
    case tournamentsConstants.ADD_PLAYER_TO_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          players: [action.playerId, ...state.tournament.players],
        },
      };
    case playersConstants.BUST_PLAYER_SUCCESS:
      let bust;
      if (state.tournament.bust === undefined) {
        bust = [action.playerId];
      } else {
        bust = [...state.tournament.bust, action.playerId];
      }

      return {
        ...state,
        tournament: {
          ...state.tournament,
          bust: bust,
          penultimateEliminated:
            bust.length === state.tournament.players.length - 1,
        },
      };

    case playersConstants.UNBUST_PLAYER_SUCCESS:
      let unbust;
      if (state.tournament.bust === undefined) {
        unbust = [];
      } else {
        const index = state.tournament.bust.indexOf(action.playerId);
        unbust = [...state.tournament.bust];
        unbust.splice(index, 1);
      }
      return {
        ...state,
        tournament: {
          ...state.tournament,
          bust: unbust,
          penultimateEliminated:
            unbust.length === state.tournament.players.length - 1,
        },
      };

    case tournamentsConstants.SET_PENULTIMATE_ELIMINATED_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          penultimateEliminated: action.penultimateEliminated,
        },
      };

    case tournamentsConstants.SET_GAME_ENDED_SUCCESS:
      const playerPositions = getPlayerInBustOrder(
        action.ledger,
        state.tournament.players,
        state.tournament.bust,
        state.players
      );

      return {
        ...state,
        tournament: {
          ...state.tournament,
          gameEnded: action.gameEnded,
          penultimateEliminated: false,
          finalStats: playerPositions,
        },
      };

    case pointsConstants.GET_POINTS_SUCCESS:
      const updatedFinalStats = state.tournament.finalStats.map(
        (player, index) => {
          // Assign points based on position in finalStats array
          const position = index + 1; // Assuming player position starts from 1
          const pointsForPosition = action.points[position] || 0; // Default to 0 if no points found
          return { ...player, points: parseFloat(pointsForPosition) };
        }
      );

      return {
        ...state,
        tournament: {
          ...state.tournament,
          finalStats: updatedFinalStats,
        },
      };

    case pointsConstants.GET_POINTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case statsConstants.SUBMIT_STATS_SUCCESS:
      return {
        ...state,
        leagueTableUrl: action.result,
      };

    case tournamentsConstants.SET_TOURNAMENT_FINAL_STATS_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          finalStats: action.finalStats,
        },
      };

    case tournamentsConstants.GET_TOURNAMENT_DETAILS_SUCCESS:
      state = {
        ...state,
        activeTournamentId: action.tournament.id,
        tournament: {
          ...state.tournament,
          details: action.tournament,
          players: [],
        },
      };

      action.tournament.players.forEach((player) => {
        state = {
          ...state,
          tournament: {
            ...state.tournament,
            players: [player, ...state.tournament.players],
          },
        };
      });

      return state;

    case tournamentsConstants.START_TOURNAMENT_SUCCESS:
      return {
        ...state,
        tournament: { ...state.tournament, inPlay: true, play: true },
      };
    case timerConstants.TOGGLE_TIMER_STATE_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          inPlay: true,
          play: !state.tournament.play,
        },
      };
    case 'UPDATE_PRIZE_ALLOCATION_TYPE':
      prizeAllocation = state.tournament.pot.prizeAllocation;
      state.tournament.pot['cashMode'] = action.cash;

      return {
        ...state,
        tournament: {
          ...state.tournament,
          pot: { ...state.tournament.pot, prizeAllocation: prizeAllocation },
        },
      };

    case potConstants.UPDATE_PRIZE_ALLOCATIONS_SUCCESS:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          pot: {
            ...state.tournament.pot,
            cashRounding: action.cashRounding,
            prizeAllocation: action.prizeAllocations,
            selectedPrizeStructureId: action.selectedPrizeStructureId,
          },
        },
      };

    case venuesConstants.GET_VENUES_SUCCESS:
      return {
        ...state,
        venues: action.venues,
      };

    case prizesConstants.GET_PRIZES_SUCCESS:
      let structure = {};
      let rounding = 5;

      if (action.prizes.length > 0) {
        rounding = parseInt(action.prizes[0].rounding);
        for (let i in action.prizes[0].structure) {
          structure = {
            ...structure,
            [i]: {
              percentage: action.prizes[0].structure[i],
              cash: 0,
            },
          };
        }
      }

      return {
        ...state,
        prizes: action.prizes,
        tournament: {
          ...state.tournament,
          pot: {
            ...state.tournament.pot,
            cashRounding: rounding,
            prizeAllocation: { ...structure },
          },
        },
      };
    case tournamentsConstants.CLEAR_TOURNAMENT_SUCCESS:
      return {
        ...initialState,
        // activeTournamentId: null,
        // leagueTableUrl: null,
        // tournament: { ...initialState.tournament },
      };
    case playersConstants.SET_DEFAULT_BUYIN:
      return {
        ...state,
        tournament: {
          ...state.tournament,
          pot: {
            ...state.tournament.pot,
            buyInChips: action.chips,
            buyIn: action.cash,
          },
        },
      };

    // Other case handlers...
    case tournamentsConstants.RESET_TOURNAMENT_STATE:
      return {
        ...state,
        tournament: {
          ...initialState.tournament,
          secondsRemaining: 1200, // Reset tournament state
        },
        activeTournamentId: null,
        leagueTableUrl: null,
      };
    case blindsConstants.SELECT_BLIND_STRUCT:
      const selectedBlindStructure = state.blinds.find(
        (blind) => blind.id === action.payload
      );
      const newState = {
        ...state,
        tournament: {
          ...state.tournament,
          activeBlindStructure: selectedBlindStructure,
          activeBlindIdx: state.blinds.findIndex(
            (blind) => blind.id === action.payload
          ),
        },
      };
      return newState;

    default:
      return state;
  }
};
