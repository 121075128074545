import axios from 'axios';
import qs from 'qs';
import { saveJWT } from '../helpers/auth';
import AuthenticatedEndpoint from '../helpers/api';

function login(username, password) {
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  return axios
    .post(
      `https://www.travispokertimer.com/pokeradmin/timer-api.php?op=login`,
      qs.stringify({ username, password }),
      config
    )
    .then((res) => {
      if (res.status === 200) {
        saveJWT(res.data.jwt);
      }
      return res.data;
    });
}

function logout(payload) {
  return AuthenticatedEndpoint.post(null, payload, {
    params: {
      op: `logout`,
    },
  });
}

export const userActions = {
  login,
  logout,
};
