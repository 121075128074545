import React from "react";
import { Statistic } from "semantic-ui-react";
import { connect } from "react-redux";
import { ledgerTotals } from "../helpers/ledger";
import {
  calculatePrizeWithRounding,
  ordinalSuffixOf,
} from "../helpers/prizeUtils";

class PrizePoolWidget extends React.Component {
  getPlayerAvgCash() {
    if (this.props.tournamentPlayers.length === 0) {
      return "n/a";
    }

    const totalChips = ledgerTotals(this.props.ledger).totalCash;
    return Math.floor(totalChips / this.props.tournamentPlayers.length);
  }

  getPlayersLabel() {
    return this.props.tournamentPlayers.length === 1 ? "Player" : "Players";
  }

  getPrizePlaces() {
    const totalCashInPot = ledgerTotals(this.props.ledger).totalCash;
    const cashRounding = this.props.pot.cashRounding;
    let prizes = this.props.pot.prizeAllocation;

    const totalPrizes = Object.keys(prizes).length;
    const prizesToShow = {};

    for (let i = totalPrizes; i > 0; i--) {
      if (this.props.pot.prizeAllocation[i].percentage > 0) {
        prizesToShow[i] = this.props.pot.prizeAllocation[i];
      }
    }

    return (
      <Statistic.Group className="add-padding prize-pool-statistic-container">
        {Object.keys(prizesToShow).map((idx) => {
          const placePrize = prizesToShow[idx];
          return (
            <Statistic size="tiny" key={idx} className="prize-pool-statistic">
              <Statistic.Label>
                {ordinalSuffixOf(parseInt(idx))}
              </Statistic.Label>
              <Statistic.Value>
                {calculatePrizeWithRounding(
                  placePrize.percentage,
                  totalCashInPot,
                  cashRounding
                )}
              </Statistic.Value>
            </Statistic>
          );
        })}
      </Statistic.Group>
    );
  }

  render(props) {
    return (
      <Statistic.Group horizontal>
        <Statistic className="prize-pool-cash-total">
          <Statistic.Label>Prize Pool: </Statistic.Label>
          <Statistic.Value>
            {ledgerTotals(this.props.ledger).totalCash}
          </Statistic.Value>
        </Statistic>
        {this.getPrizePlaces()}
      </Statistic.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pot: state.game.tournament.pot,
    ledger: state.ledger,
    tournamentPlayers: state.game.tournament.players,
  };
};

export default connect(mapStateToProps, {})(PrizePoolWidget);
