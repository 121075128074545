import React from 'react';
import { Button, Grid, Modal } from 'semantic-ui-react';
import TournamentPlayer from './TournamentPlayer';
import PlayerSelector from './PlayerSelector';
import CreatePlayer from './CreatePlayer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { playersActions } from '../actions/players.actions';

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatePlayerModalOpen: false,
    };
  }

  handleOpenCreatePlayerModal = () => {
    this.setState({ isCreatePlayerModalOpen: true });
  };

  closeCreatePlayerModal = () => {
    this.setState({ isCreatePlayerModalOpen: false });
    this.props.getPlayers(); // Call getPlayers after closing the modal
  };

  idToPlayer = (id) => {
    const playerMap = {};
    this.props.leaguePlayers.forEach((item) => {
      playerMap[item.id] = item;
    });
    return playerMap[parseInt(id)];
  };

  renderTableData = () => {
    return this.props.tournamentPlayers.map((playerId) => {
      const { name, id, photo } = this.idToPlayer(playerId);
      return <TournamentPlayer name={name} key={id} id={id} photo={photo} />;
    });
  };

  render() {
    return (
      <Modal
        closeIcon
        trigger={<Button>Players</Button>}
        size="fullscreen"
        centered={false}
        className="players-modal modal-dimmer-bg-color"
      >
        <Modal.Header className="players-modal-header">Players</Modal.Header>
        <PlayerSelector />
        <Modal.Content className="players-modal-content modal-bg-content-container">
          <Grid divided="vertically">
            <Grid.Row>
              <Grid.Column className="six wide column">
                <h2 className="players-modal-secodary-headers">Name</h2>
              </Grid.Column>
              <Grid.Column className="four wide column">
                <h2 className="players-modal-secodary-headers">Cash</h2>
              </Grid.Column>
              <Grid.Column className="four wide column">
                <h2 className="players-modal-secodary-headers">Chips</h2>
              </Grid.Column>
              <Grid.Column className="two wide column"> </Grid.Column>
            </Grid.Row>

            {this.renderTableData()}
          </Grid>
        </Modal.Content>
        <div className="players-modal-bottom">
          <div className="add-players-button">
            <Button onClick={this.handleOpenCreatePlayerModal}>
              Create Player
            </Button>
          </div>
        </div>
        {/* Include the CreatePlayer modal and control its visibility */}
        <CreatePlayer
          open={this.state.isCreatePlayerModalOpen}
          onClose={this.closeCreatePlayerModal}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  leaguePlayers: state.game.players,
  tournamentPlayers: state.game.tournament.players,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPlayers: playersActions.getPlayers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Players);
