import React from 'react';
import './style.css';
import { connect } from 'react-redux';
import { tournamentsActions } from '../actions/tournaments.actions';
import { timerActions } from '../actions/timer.actions';

class ClockWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  remainingTimeFromClockClick(e) {
    let svg1 = document.getElementById('svg1').getBoundingClientRect();
    let x = svg1.x + svg1.width / 2;
    let y = svg1.y + svg1.height / 2;
    let angle =
      ((Math.atan2(y - e.clientY, -x + e.clientX) * 180) / Math.PI - 90) * -1;
    let FinalValue;
    if (angle > 0) {
      FinalValue = (angle * this.props.secondsInRound) / 360;
    } else {
      FinalValue =
        (((Math.atan2(y - e.clientY, x - e.clientX) * 180) / Math.PI + 270) *
          this.props.secondsInRound) /
        360;
    }
    return Math.round(FinalValue);
  }
  clickClock = (e) => {
    this.props.setSecondsRemaining(this.remainingTimeFromClockClick(e));
  };

  activeRound() {
    return this.props.rounds.structure[this.props.activeRound];
  }

  render() {
    // Size of the enclosing square
    const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - Number(this.props.strokeWidth) * 2) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${Number(sqSize)} ${Number(sqSize)}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset =
      (dashArray -
        dashArray * (this.props.secondsRemaining / this.props.secondsInRound)) |
      0;

    return (
      <div id="clockShow">
        <svg
          id="svg1"
          onClick={(e) => {
            this.clickClock(e);
          }}
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}
        >
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
          />
          <circle
            className="circle-progress2"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${Number(this.props.strokeWidth) * 2}px`}
            transform={`rotate(-90 ${this.props.sqSize / 2} ${
              this.props.sqSize / 2
            })`}
          />

          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${this.props.sqSize / 2} ${
              this.props.sqSize / 2
            })`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset,
            }}
          />

          <circle
            className="circle-dashes"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            style={{
              strokeDasharray: '15 3.19',
            }}
          />
        </svg>
        <div id="clockInfo">
          <div id="clockInfoTxtRound">Round {this.props.round}</div>
          <div id="clockInfoTxtDigits">
            {new Date(this.props.secondsRemaining * 1000)
              .toISOString()
              .substr(14, 5)}
          </div>
          <div id="clockInfoTxtTotal">
            total time{' '}
            {new Date(this.props.secondsPlayedInTotal * 1000)
              .toISOString()
              .substr(11, 8)}
          </div>
          <div id="clockInfoTxtButtons">
            <button
              className="leftRight"
              onClick={() => this.props.backRound()}
            >
              &lt;
            </button>

            <button
              className="go"
              onClick={() => {
                if (!this.props.inPlay) {
                  this.props.startTournament();
                } else {
                  this.props.togglePlayState();
                }
              }}
            >
              {this.props.play ? 'Pause' : 'GO!'}
            </button>
            <button
              className="leftRight"
              onClick={() => this.props.forwardRound()}
            >
              &gt;
            </button>
          </div>
          <div
            style={{
              visibility: this.props.inBreak ? 'visible' : 'hidden',
            }}
          >
            Break
          </div>
        </div>
      </div>
    );
  }
}

ClockWidget.defaultProps = {
  sqSize: 200,
  strokeWidth: 22,
};

const mapStateToProps = (state) => {
  return {
    tournamentPlayers: state.game.tournament.players,
    rounds: state.game.tournament.activeBlindStructure,
    activeRound: state.game.tournament.activeRound,
    inPlay: state.game.tournament.inPlay,
    play: state.game.tournament.play,
    inBreak: state.game.tournament.inBreak,
  };
};

export default connect(mapStateToProps, {
  backRound: tournamentsActions.backRound,
  forwardRound: tournamentsActions.forwardRound,
  togglePlayState: timerActions.togglePlayState,
  startTournament: tournamentsActions.startTournament,
  setSecondsRemaining: timerActions.setSecondsRemaining,
})(ClockWidget);
