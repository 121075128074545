import React from "react";

import { Header, Icon, Image, Table } from "semantic-ui-react";

import { connect } from "react-redux";
import ContentEditable from "react-contenteditable";
import { tournamentsActions } from "../actions/tournaments.actions";
import { arrayMove } from "../helpers/arrayUtils";

class TournamentStatsTable extends React.Component {
  handleRowUp(players, playerId) {
    const index = players.findIndex((x) => x.id === playerId);
    if (index - 1 >= 0) {
      players = [...arrayMove(players, index, index - 1)];
      this.props.setTournamentFinalStats(players);
    }
  }

  handleRowDown(players, playerId) {
    const index = players.findIndex((x) => x.id === playerId);
    if (index + 1 < players.length) {
      players = [...arrayMove(players, index, index + 1)];
      this.props.setTournamentFinalStats(players);
    }
  }

  handleCashUpdate(players, playerId, newValue) {
    for (let i in players) {
      if (players[i].id === playerId) {
        players[i].cash = newValue;
        break;
      }
    }
    this.props.setTournamentFinalStats([...players]);
  }

  handlePointsUpdate(players, playerId, newValue) {
    for (let i in players) {
      if (players[i].id === playerId) {
        players[i].points = newValue;
        break;
      }
    }
    this.props.setTournamentFinalStats([...players]);
  }

  renderTableData() {
    return this.props.players.map((player, index) => {
      const { id, name, photo, cash, points, profit, prize } = player;

      return (
        <Table.Row key={id} className="final-stats-headers">
          <Table.Cell>
            <Header as="h4" image className="set-white-color">
              {index + 1}
            </Header>
          </Table.Cell>
          <Table.Cell>
            <Image src={photo} circular size="mini" />
          </Table.Cell>
          <Table.Cell
            className="tournament-players-names"
            id="players-names-in-tournament"
          >
            {name}
          </Table.Cell>
          <Table.Cell>
            <ContentEditable
              html={cash.toString()}
              data-column="cash"
              data-row={id}
              onChange={(e) => {
                this.handleCashUpdate(
                  this.props.players,
                  id,
                  parseInt(e.target.value || 0)
                );
              }}
            />
          </Table.Cell>
          <Table.Cell>{prize}</Table.Cell>
          <Table.Cell>{profit}</Table.Cell>
          <Table.Cell>
            <ContentEditable
              html={points.toString() || ""}
              data-column="points"
              data-row={id}
              onChange={(e) => {
                this.handlePointsUpdate(
                  this.props.players,
                  id,
                  parseFloat(e.target.value || 0)
                );
              }}
            />
          </Table.Cell>
          <Table.Cell>
            <Icon
              name="angle up"
              onClick={(e) => {
                this.handleRowUp(this.props.players, id);
                this.props.updateFinalStatsCallback(this.props.players);
              }}
            />
            <Icon
              name="angle down"
              onClick={(e) => {
                this.handleRowDown(this.props.players, id);
                this.props.updateFinalStatsCallback(this.props.players);
              }}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  }
  render() {
    return (
      <Table basic="very" celled striped>
        <Table.Header>
          <Table.Row className="final-stats-headers">
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.HeaderCell colSpan={2}>Player</Table.HeaderCell>
            <Table.HeaderCell>Spend</Table.HeaderCell>
            <Table.HeaderCell>Prize</Table.HeaderCell>
            <Table.HeaderCell>Profit</Table.HeaderCell>
            <Table.HeaderCell>Points</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{this.renderTableData()}</Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ledger: state.ledger,
    tournamentPlayers: state.game.tournament.players,
    bustPlayers: state.game.tournament.bust,
    leaguePlayers: state.game.players,
  };
};

export default connect(mapStateToProps, {
  setTournamentFinalStats: tournamentsActions.setTournamentFinalStats,
})(TournamentStatsTable);
