import AuthenticatedEndpoint from '../helpers/api';

function getPlayers() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getplayers`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function createPlayer(playername, nickname, team, email, profile, country) {
  return AuthenticatedEndpoint.post(
    null,
    {
      playername: playername,
      nickname: nickname,
      team: team,
      email: email,
      profile: profile,
      country: country,
    },
    {
      params: {
        op: `createplayer`,
      },
    }
  )
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      console.error('Error creating player:', err);
      throw err; // Rethrow the error to handle it later
    });
}

export const playersServices = {
  getPlayers,
  createPlayer,
};
