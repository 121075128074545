import React from "react";

class ChipsIndicator extends React.Component {
  render(props) {
    return (
      <div className="content-primary-chips-indicator">
        <p>Chips</p>
        <p>Values</p>
      </div>
    );
  }
}

export default ChipsIndicator;
