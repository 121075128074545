import { tournamentsServices } from '../services/tournaments.service';
import { tournamentsConstants } from '../constants/tournaments.constants';
import { blindsActions } from './blinds.actions';
import { playersActions } from './players.actions';
import { venuesActions } from './venues.actions';
import { prizesActions } from './prizes.actions';

function createTournament(tournamentname, tournamentdate, venueId) {
  return (dispatch) => {
    const promise = tournamentsServices.createTournament(
      tournamentname,
      tournamentdate,
      venueId
    );

    promise.then(
      (tournament) => {
        dispatch(success(tournament));
        dispatch(getTournamentDetails(tournament.tournamentid));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(tournament) {
    return { type: tournamentsConstants.CREATE_TOURNAMENT_SUCCESS, tournament };
  }

  function failure(error) {
    return { type: tournamentsConstants.CREATE_TOURNAMENT_FAILURE, error };
  }
}

function getTournaments() {
  return (dispatch) => {
    const promise = tournamentsServices.getTournaments();

    promise.then(
      (tournaments) => {
        dispatch(success(tournaments));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(tournaments) {
    return { type: tournamentsConstants.GET_TOURNAMENTS_SUCCESS, tournaments };
  }

  function failure(tournaments) {
    return { type: tournamentsConstants.GET_TOURNAMENTS_FAILURE, tournaments };
  }
}

function getTournamentDetails(tournamentId) {
  return (dispatch) => {
    const promise = tournamentsServices.getTournamentDetails(tournamentId);

    promise.then(
      (tournament) => {
        dispatch(success(tournament));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(tournament) {
    return {
      type: tournamentsConstants.GET_TOURNAMENT_DETAILS_SUCCESS,
      tournament,
    };
  }

  function failure(tournaments) {
    return { type: tournamentsConstants.GET_TOURNAMENTS_FAILURE, tournaments };
  }
}

function addPlayerToTournament(playerId, tournamentId, cashAmount, chipAmount) {
  return (dispatch) => {
    const promise = tournamentsServices.addPlayerToTournament(
      playerId,
      tournamentId,
      cashAmount,
      chipAmount
    );

    promise.then(
      (tournament) => {
        dispatch(success(tournament, cashAmount, chipAmount));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(tournament) {
    return {
      type: tournamentsConstants.ADD_PLAYER_TO_TOURNAMENT_SUCCESS,
      tournament: tournament,
      playerId: playerId,
      cashAmount: cashAmount,
      chipAmount: chipAmount,
    };
  }

  function failure(error) {
    return {
      type: tournamentsConstants.ADD_PLAYER_TO_TOURNAMENT_FAILURE,
      error,
    };
  }
}

function removePlayerFromTournament(playerId, tournamentId) {
  return (dispatch) => {
    const promise = tournamentsServices.removePlayerFromTournament(
      playerId,
      tournamentId
    );

    promise.then(
      () => {
        dispatch(success());
        dispatch(getTournamentDetails(tournamentId));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(tournament) {
    return {
      type: tournamentsConstants.REMOVE_PLAYER_FROM_TOURNAMENT_SUCCESS,
      tournament: tournament,
      playerId: playerId,
    };
  }

  function failure(error) {
    return {
      type: tournamentsConstants.REMOVE_PLAYER_FROM_TOURNAMENT_FAILURE,
      error,
    };
  }
}

function backRound() {
  return {
    type: tournamentsConstants.BACK_ROUND_SUCCESS,
  };
}

function forwardRound() {
  return {
    type: tournamentsConstants.FORWARD_ROUND_SUCCESS,
  };
}

function setPenultimateEliminated(penultimateEliminated) {
  return {
    type: tournamentsConstants.SET_PENULTIMATE_ELIMINATED_SUCCESS,
    penultimateEliminated: penultimateEliminated,
  };
}

function setGameEnded(gameEnded, ledger) {
  return {
    type: tournamentsConstants.SET_GAME_ENDED_SUCCESS,
    ledger: ledger,
    gameEnded: gameEnded,
  };
}

function startTournament() {
  return {
    type: tournamentsConstants.START_TOURNAMENT_SUCCESS,
  };
}

function setTournamentName(name) {
  return {
    type: tournamentsConstants.SET_TOURNAMENT_NAME_SUCCESS,
    name: name,
  };
}

function setTournamentDate(date) {
  return {
    type: tournamentsConstants.SET_TOURNAMENT_DATE_SUCCESS,
    date: date,
  };
}

function setTournamentVenue(venue, venueid) {
  return {
    type: tournamentsConstants.SET_TOURNAMENT_VENUE_SUCCESS,
    venue: venue,
    venueid: venueid,
  };
}

function setTournamentFinalStats(finalStats) {
  return {
    type: tournamentsConstants.SET_TOURNAMENT_FINAL_STATS_SUCCESS,
    finalStats: finalStats,
  };
}

function clearTournament() {
  return (dispatch) => {
    dispatch({ type: tournamentsConstants.CLEAR_TOURNAMENT_SUCCESS });
    dispatch(blindsActions.getBlinds());
    dispatch(playersActions.getPlayers());
    dispatch(venuesActions.getVenues());
    dispatch(tournamentsActions.getTournaments());
    dispatch(prizesActions.getPrizes());
  };
}

function resetTournamentState() {
  return {
    type: tournamentsConstants.RESET_TOURNAMENT_STATE,
  };
}

export const tournamentsActions = {
  createTournament,
  getTournaments,
  getTournamentDetails,
  addPlayerToTournament,
  removePlayerFromTournament,
  setPenultimateEliminated,
  setGameEnded,
  forwardRound,
  backRound,
  startTournament,
  setTournamentName,
  setTournamentDate,
  setTournamentVenue,
  setTournamentFinalStats,
  clearTournament,
  resetTournamentState,
};
