import AuthenticatedEndpoint from "../helpers/api";

function createVenue(venuename) {
  return AuthenticatedEndpoint.post(
    null,
    { venuename: venuename },
    {
      params: {
        op: `createvenue`,
      },
    }
  ).then((res) => {
    return res.data.result;
  });
}

function getVenues() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getvenues`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

export const venuesServices = {
  createVenue,
  getVenues,
};
