import AuthenticatedEndpoint from '../helpers/api';

function getBlinds() {
  return AuthenticatedEndpoint.get(null, {
    params: {
      op: `getblinds`,
    },
  }).then((res) => {
    return res.data.result;
  });
}

function addBlinds(blindsname, blindsstructure) {
  return AuthenticatedEndpoint.post(
    null,
    { blindsname: blindsname, blindsstructure: blindsstructure },
    {
      params: {
        op: `blindsadd`,
      },
    }
  ).then((res) => {
    return res.data;
  });
}

function deleteBlinds(blinds) {
  return AuthenticatedEndpoint.post(
    null,
    {},
    {
      params: {
        op: `blindsdelete`,
        blindsid: blinds,
      },
    }
  ).then((res) => {
    return res.data.result;
  });
}

export const blindsServices = {
  getBlinds,
  addBlinds,
  deleteBlinds,
};
