import React from 'react';

import { connect } from 'react-redux';
import { Button, Icon, Input, Image, Grid } from 'semantic-ui-react';
import { ledgerTotals } from '../helpers/ledger';
import { addToLedger, updatePlayerBuyin } from '../actions/ledger.actions';
import { tournamentsActions } from '../actions/tournaments.actions';
import { bindActionCreators } from 'redux';

const TournamentPlayer = ({
  ledger,
  id,
  inPlay,
  updateLedgerForPlayerAndType,
  name,
  tournamentId,
  removePlayerFromTournament,
  photo,
}) => {
  const updateChips = ({ value }) => {
    const chips = value === '' ? 0 : parseInt(value);
    updateLedgerForPlayerAndType(
      parseInt(id),
      ledgerTotals(ledger, parseInt(id)).totalCash,
      chips
    );
  };

  const updateCash = ({ value }) => {
    const cash = value === '' ? 0 : parseInt(value);
    updateLedgerForPlayerAndType(
      parseInt(id),
      cash,
      ledgerTotals(ledger, parseInt(id)).totalChips
    );
  };

  const renderCash = () => {
    if (inPlay) {
      return (
        <Input
          disabled={inPlay}
          value={ledgerTotals(ledger, parseInt(id)).totalCash}
          onChange={({ target }) => {
            updateCash(target);
          }}
        />
      );
    } else {
      return (
        <p className="players-modal-secodary-headers">
          {ledgerTotals(ledger, parseInt(id)).totalCash}
        </p>
      );
    }
  };

  const renderChips = () => {
    if (inPlay) {
      return (
        <Input
          disabled={inPlay}
          value={ledgerTotals(ledger, parseInt(id)).totalChips}
          onChange={({ target }) => {
            updateChips(target);
          }}
        />
      );
    } else {
      return (
        <p className="players-modal-secodary-headers">
          {ledgerTotals(ledger, parseInt(id)).totalChips}
        </p>
      );
    }
  };

  return (
    <Grid.Row className="players-modal-rows">
      <Grid.Column className="players-modal-columns six wide column">
        <div className="players-modal-name-item">
          <div className="player-image-modal">
            <Image avatar src={photo} circular size="mini" />
          </div>
          <p className="players-modal-secodary-headers, tournament-players-names-modal">
            {name}
          </p>
        </div>
      </Grid.Column>
      <Grid.Column className="four wide column">{renderCash()}</Grid.Column>
      <Grid.Column className="four wide column">{renderChips()}</Grid.Column>
      <Grid.Column className="two wide column">
        <Button
          icon
          onClick={() => {
            removePlayerFromTournament(id, tournamentId);
          }}
          style={{ display: inPlay ? 'none' : 'inline-block' }}
        >
          <Icon name="delete" />
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};

const mapStateToProps = (state) => {
  return {
    ledger: state.ledger,
    inPlay: state.game.tournament.inPlay,
    tournamentId: state.game.tournament.details.id,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      removePlayerFromTournament: tournamentsActions.removePlayerFromTournament,
      addToLedger: addToLedger,
      updateLedgerForPlayerAndType: updatePlayerBuyin,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TournamentPlayer);
