import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { tournamentsActions } from '../actions/tournaments.actions';

class TournamentResults extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { open, onClose, leagueTableUrl } = this.props;

    return (
      <Modal
        open={open}
        onClose={onClose}
        closeIcon
        size="small"
        centered={true}
        className="players-modal modal-dimmer-bg-color"
      >
        <Modal.Header className="players-modal-header">
          Tournament results successfully uploaded
        </Modal.Header>
        <Modal.Content className="modal-bg-content-container">
          <div className="tournament-results-buttons">
            <Button
              type="button"
              className="tournament-results-button"
              onClick={() => {
                this.props.clearTournament(),
                  this.props.setGameEnded(false, this.props.ledger);
                this.props.onClose();
              }}
            >
              New Tournament
            </Button>

            <Button
              className="tournament-results-button"
              type="button"
              onClick={() => window.open(leagueTableUrl, '_blank')}
              disabled={leagueTableUrl === null}
            >
              View League Table
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ledger: state.ledger,
    leagueTableUrl: state.game.leagueTableUrl,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearTournament: tournamentsActions.clearTournament,
      setGameEnded: tournamentsActions.setGameEnded,
      resetTournamentState: tournamentsActions.resetTournamentState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TournamentResults);
