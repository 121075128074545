import React from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { userActions } from '../actions/user.actions';
import { Redirect } from 'react-router';

class LoginForm extends React.Component {
  handlerSubmit(event) {
    const username = event.target[0].value;
    const password = event.target[1].value;
    this.props.login(username, password);
  }

  render() {
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        {this.props.jwt ? <Redirect to="/" /> : null}
        <Grid.Column className="login-container ">
          <Header as="h2" color="teal" textAlign="center">
            <h2 className="login-title">Log-in to your account</h2>
          </Header>
          <Form size="large" onSubmit={(e) => this.handlerSubmit(e)}>
            <Segment stacked>
              <Message
                error
                header="Login Error"
                content={this.props.loginError}
                visible={this.props.loginError !== null}
              />
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Username"
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
              />
              <Button
                style={{
                  width: '200px',
                  marginTop: '8px',
                  marginBottom: '16px',
                }}
                className="ui button rounds-action-buttons"
              >
                Log in
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    jwt: state.user.jwt,
    loginError: state.user.loginError,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  login: (username, password) =>
    dispatch(userActions.login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
