import React from 'react';
import { Grid } from 'semantic-ui-react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import { blindsActions } from '../actions/blinds.actions';

class Round extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ante: props.ante,
      sb: props.smallBlind,
      bb: props.bigBlind,
      dur: props.roundMinutes,
      break: this.isBreak(props.break),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.ante !== this.props.ante ||
      prevProps.smallBlind !== this.props.smallBlind ||
      prevProps.bigBlind !== this.props.bigBlind ||
      prevProps.roundMinutes !== this.props.roundMinutes ||
      prevProps.break !== this.props.break
    ) {
      this.setState({
        ante: this.props.ante,
        sb: this.props.smallBlind,
        bb: this.props.bigBlind,
        dur: this.props.roundMinutes,
        break: this.isBreak(this.props.break),
      });
    }
  }

  isBreak(breakFlag) {
    return breakFlag && breakFlag?.toString() === '1';
  }

  handleContentEditableUpdate = (event) => {
    const {
      currentTarget: {
        dataset: { row, column },
      },
    } = event;
    let structure = this.props.activeBlindStructure;
    structure.structure[row][column] = this.state[column];

    this.props.updateBlindStruct(this.props.idx, structure);
  };

  handleCheckBreak = (event, row, column) => {
    let structure = this.props.activeBlindStructure;

    if (event.target.checked) {
      structure.structure[row][column] = 1;
      structure.structure.splice(row + 1, 0, { ...structure.structure[row] });
      structure.structure[row + 1]['break'] = 0;
    } else {
      structure.structure[row][column] = 0;
      structure.structure.splice(row, 1);
    }

    this.props.updateBlindStruct(this.props.idx, { ...structure });
  };

  isChecked = (row, column) => {
    let structure = this.props.activeBlindStructure;
    return structure.structure[row][column];
  };

  render() {
    return (
      <div>
        <Grid divided="vertically" key={this.props.idx}>
          <Grid.Row>
            <Grid.Column className="two wide column">
              <div className="align-text-end">
                {!this.state.break ? this.props.roundNumber : '-'}
              </div>
            </Grid.Column>
            <Grid.Column className="three wide column">
              <div>
                <ContentEditable
                  html={!this.state.break ? this?.state?.sb?.toString() : '-'}
                  data-column="sb"
                  data-row={this.props.idx}
                  className="content-editable"
                  onChange={(e) => {
                    this.setState({ sb: parseInt(e.target.value) || 0 });
                  }}
                  onBlur={this.handleContentEditableUpdate}
                />
              </div>
            </Grid.Column>
            <Grid.Column className="three wide column">
              <div>
                <ContentEditable
                  html={!this.state.break ? this?.state?.bb?.toString() : '-'}
                  data-column="bb"
                  data-row={this.props.idx}
                  className="content-editable"
                  onChange={(e) => {
                    this.setState({ bb: parseInt(e.target.value) || 0 });
                  }}
                  onBlur={this.handleContentEditableUpdate}
                />
              </div>
            </Grid.Column>
            <Grid.Column className="three wide column">
              <div>
                <ContentEditable
                  html={!this.state.break ? this?.state?.ante?.toString() : '-'}
                  data-column="ante"
                  data-row={this.props.idx}
                  className="content-editable"
                  onChange={(e) => {
                    this.setState({ ante: parseInt(e.target.value) || 0 });
                  }}
                  onBlur={this.handleContentEditableUpdate}
                />
              </div>
            </Grid.Column>
            <Grid.Column className="three wide column">
              <div>
                <ContentEditable
                  html={(this?.state?.dur / 60)?.toString()}
                  data-column="dur"
                  data-row={this.props.idx}
                  className="content-editable"
                  onChange={(e) => {
                    this.setState({ dur: parseInt(e.target.value) * 60 || 0 });
                  }}
                  onBlur={this.handleContentEditableUpdate}
                />
              </div>
            </Grid.Column>
            <Grid.Column className="two wide column">
              <div className="modal-break-column">
                <div className="modal-break-column-input-wrapper">
                  <input
                    type="checkbox"
                    checked={this.state.break}
                    onChange={(e) =>
                      this.handleCheckBreak(e, this.props.idx, 'break')
                    }
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeBlindStructure: state.game.tournament.activeBlindStructure,
    blinds: state.game.blinds,
  };
};

export default connect(mapStateToProps, {
  updateBlindStruct: blindsActions.updateBlindStruct,
})(Round);
