import { potConstants } from '../constants/pot.constants';

function updatePrizeAllocations(
  prizeAllocations,
  cashRounding,
  selectedPrizeStructureId
) {
  return {
    type: potConstants.UPDATE_PRIZE_ALLOCATIONS_SUCCESS,
    prizeAllocations: prizeAllocations,
    cashRounding: cashRounding,
    selectedPrizeStructureId: selectedPrizeStructureId,
  };
}

function updatePrizeAllocationType(cash) {
  return {
    type: 'UPDATE_PRIZE_ALLOCATION_TYPE',
    cash: cash,
  };
}

export const potActions = {
  updatePrizeAllocations,
  updatePrizeAllocationType,
};
