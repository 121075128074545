import { BrowserRouter as Router, Route } from 'react-router-dom';
import React from 'react';

import LoginForm from './Login';
import App from './App';
import PrivateRoute from './PrivateRoute';

class DesktopContainer extends React.Component {
  render() {
    return (
      <Router basename={`${process.env.PUBLIC_URL}`}>
        <PrivateRoute exact path="/" component={App} />
        <Route exact path="/login" component={LoginForm} />
      </Router>
    );
  }
}

export default DesktopContainer;
