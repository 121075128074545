import {
  bustPlayer,
  buyinPlayer,
  ledgerTotals,
  playerAddon,
  playerRebuy,
  startTournament,
  unbustPlayer,
  getPlayerBuyIn,
} from '../helpers/ledger';
import { tournamentsConstants } from '../constants/tournaments.constants';
import { playersConstants } from '../constants/players.constants';

const initialState = [];

export const ledgerReducer = (state = initialState, action) => {
  switch (action.type) {
    case tournamentsConstants.GET_TOURNAMENT_DETAILS_SUCCESS:
      const oldLedger = state;
      state = [];
      action.tournament.players.forEach((player) => {
        let entry = getPlayerBuyIn(oldLedger, player);
        if (entry === undefined) {
          entry = buyinPlayer(
            parseInt(player),
            action?.tournament?.buyin_chips || 200,
            action?.tournament?.buyin_cash || 20,
            'credit'
          );
        }
        state = [...state, entry];
      });

      return state;

    case tournamentsConstants.START_TOURNAMENT_SUCCESS:
      return [...state, startTournament()];

    case tournamentsConstants.ADD_PLAYER_TO_TOURNAMENT_SUCCESS:
      return [
        ...state,
        buyinPlayer(
          parseInt(action.playerId),
          action.chipAmount || state.tournament.pot.buyInChips,
          action.cashAmount || state.tournament.pot.buyIn,
          'credit'
        ),
      ];

    case playersConstants.BUST_PLAYER_SUCCESS:
      return [...state, bustPlayer(parseInt(action.playerId), 1)];

    case playersConstants.UNBUST_PLAYER_SUCCESS:
      return [...state, unbustPlayer(parseInt(action.playerId), 1)];

    case playersConstants.UPDATE_BUYIN_SUCCESS:
      const playerTotal = ledgerTotals(
        state,
        action.playerId,
        action.transactionType
      );
      return [
        ...state,
        buyinPlayer(
          parseInt(action.playerId),
          playerTotal.totalChips,
          playerTotal.totalCash,
          'debit'
        ),
        buyinPlayer(
          parseInt(action.playerId),
          action.newChips,
          action.newCash,
          'credit'
        ),
      ];

    case playersConstants.REBUY_PLAYER_SUCCESS:
      return [
        ...state,
        playerRebuy(
          parseInt(action.playerId),
          parseInt(action.chips),
          parseInt(action.cash),
          0
        ),
      ];

    case playersConstants.ADDON_PLAYER_SUCCESS:
      return [
        ...state,
        playerAddon(
          parseInt(action.playerId),
          parseInt(action.chips),
          parseInt(action.cash),
          0
        ),
      ];

    case tournamentsConstants.CLEAR_TOURNAMENT_SUCCESS:
      return [...initialState];

    default:
      return state;
  }
};
