import React from "react";
import { connect } from "react-redux";
import { Statistic } from "semantic-ui-react";

class BlindsWidget extends React.Component {
  generateStats(structure, nextRound = false) {
    if (
      structure === undefined ||
      structure === null ||
      structure.length === 0
    ) {
      return;
    }
    const getIndex = (nextRound) => {
      if (
        nextRound &&
        structure.length > 0 &&
        this.props.activeRound < structure.length - 1
      ) {
        return this.props.activeRound + 1;
      } else {
        return this.props.activeRound;
      }
    };

    const getValue = (fieldName, structure, showNextRound) => {
      if (this.props.break && !showNextRound) {
        return "-";
      }

      const nextRound = this.props.rounds.structure[this.props.activeRound + 1];
      if (showNextRound && nextRound && nextRound.break) {
        return "-";
      }

      if (showNextRound && this.props.activeRound === structure.length - 1) {
        return "-";
      }

      return structure[getIndex(showNextRound)][fieldName];
    };

    let order = [
      {
        key: "bigBlind",
        value: getValue("bb", structure, nextRound),
        label: nextRound ? "Next Big Blind" : "Big Blind",
      },
      {
        key: "smallBlind",
        value: getValue("sb", structure, nextRound),
        label: nextRound ? "Next Small Blind" : "Small Blind",
      },
      {
        key: "ante",
        value: getValue("ante", structure, nextRound),
        label: nextRound ? "Next Ante" : "Ante",
      },
    ];

    return order.map((item) => {
      return (
        <Statistic key={item.key} className="ui huge">
          <Statistic.Label>{item.label}</Statistic.Label>
          <Statistic className="ui value">{item.value}</Statistic>
        </Statistic>
      );
    });
  }

  render() {
    const { nextRound } = this.props;
    return (
      <div>
        <Statistic.Group className={nextRound ? "next-round" : ""}>
          {this.generateStats(
            this.props.activeBlindStructure.structure,
            nextRound
          )}
        </Statistic.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blinds: state.game.blinds,
    activeRound: state.game.tournament.activeRound,
    activeBlindStructure: state.game.tournament.activeBlindStructure,
    nextRounds: 2,
    rounds: state.game.tournament.activeBlindStructure,
  };
};

export default connect(mapStateToProps, {})(BlindsWidget);
