import React from "react";
import { List } from "semantic-ui-react";
import { connect } from "react-redux";
import { tournamentsActions } from "../actions/tournaments.actions";
import { bindActionCreators } from "redux";

const Tournament = ({ getTournamentDetails, id, name, onClose }) => {
  const selectTournament = (tournamentId) => {
    getTournamentDetails(tournamentId);
    onClose();
  };
  return (
    <List.Item
      as="a"
      onClick={() => {
        selectTournament(id);
      }}
    >
      {name}
    </List.Item>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getTournamentDetails: tournamentsActions.getTournamentDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
