import { playersConstants } from "../constants/players.constants";

export const addToLedger = (
  playerId,
  transationType,
  timestamp,
  chipAmount,
  cashAmount
) => {
  return {
    type: "ADD_TO_LEDGER",
    playerId: parseInt(playerId),
    transactionType: transationType,
    chipAmount: chipAmount,
    cashAmount: cashAmount,
  };
};

export const reverseTransactionType = (playerId, transactionType) => {
  return {
    type: "REVERSE_TRANSACTION_TYPE",
    playerId: parseInt(playerId),
    transactionType: transactionType,
  };
};

export const updatePlayerBuyin = (
  playerId,
  newCash,
  newChips,
  transactionType
) => {
  return {
    type: playersConstants.UPDATE_BUYIN_SUCCESS,
    playerId: parseInt(playerId),
    newCash: parseInt(newCash),
    newChips: parseInt(newChips),
    transactionType: transactionType,
  };
};
