import React from "react";

import DesktopContainer from "./components/DesktopContainer";
import "./App.css";

function App() {
  return <DesktopContainer />;
}

export default App;
